.login-form-img img {
  width: 100%;
  height: 100vh;
}
.form-dv-input input {
  width: 100%;
  background-color: #f3f3f3;
  height: 44px;
  margin: 6px 0px;
  border-radius: unset !important;
  border: 1px solid #c3cad9;
  padding: 14px;
  outline: unset;
}
/* Custom new Css */
/* This css is for normalizing styles. You can skip this. */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: start;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #dcdcdc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 2px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid #d55302;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* ***************************************** */

.login-form-all {
  width: 50%;
  margin: 0 auto;
}
.login-head h2 {
  font-size: 32px;
  font-family: hasnon-bold;
  color: var(--second-color);
  line-height: 22px;
  text-transform: uppercase;
}
.login-head p {
  font-size: 16px;
  color: var(--fourth-color);
  font-family: poppins-regular;
  margin: 0;
}
.login-head p span {
  font-family: poppins-bold;
  color: var(--third-color);
}
.login-para {
  font-size: 12px !important;
  padding-top: 5px;
}
.login-para a {
  color: var(--third-color);
  font-family: "poppins-bold";
  text-decoration: unset;
}
.remeber-and-forgot.new {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
}
.remeber-and-forgot.new a {
  font-size: 15px;
  color: var(--third-color);
  font-family: poppins-light;
  text-decoration: unset;
}
.remeber-and-forgot.new label {
  font-size: 14px;
  color: #636060;
  font-family: "poppins-light";
  text-transform: capitalize;
}
.login-head {
  padding-bottom: 50px;
}
.login-btn .submitButton {
  background-color: #f1480c;
  padding: 13px;
  width: 100%;
  border: 0;
  color: #fff;
  font-family: "poppins-meduim";
  border-radius: 5px;
  margin-top: 10px !important;
}
.form-dv-input input::placeholder {
  font-size: 13px;
  font-family: "poppins-regular";
  color: #63606070;
}
section.login-sec .row {
  display: flex;
  align-items: center;
}
.login-form-img {
  position: relative;
}
.login-form-text {
  position: absolute;
  display: flex;
  top: 0;
  right: 66px;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
}
.login-form-img .login-form-text a {
  text-decoration: none;
}
.login-all-text p {
  font-size: 10px;
  color: #ffffff5e;
  font-family: "poppins-regular";
}
.login-all-text h2 {
  color: #ffffff;
  line-height: 37px;
}

/* Begin of Register Section */
.register-form-all {
  width: 100% !important;
}
.follow-us-on p {
  font-size: 13px;
  color: #636060;
  font-family: "poppins-regular";
  margin: 0;
  text-align: left;
}
.follow-us-on a {
  color: var(--first-color);
  text-decoration: unset;
}
.follow-us-on {
  text-align: center;
  /* padding-top: 80px; */
  position: relative;
  top: 80px;
}
.login-memeber-ul li {
  font-size: 12px;
}
.login-member-dv {
  height: 160px;
}
.login-memeber-ul {
  padding: 0px 30px;
}
.my-profile-sec ul.member-ul.login-memeber-ul {
  padding: 0;
}
.login-member-col h5 {
  font-size: 16px;
  color: var(--fourth-col);
  font-family: "poppins-semibold";
  margin: 0;
}
.member-btn-login {
  width: 70%;
}
.login-memebr-btn-all {
  padding-top: 0px;
}
.member-btn-login a {
  font-size: 14px;
  padding: 10px;
}
.login-memeber-ul li {
  font-size: 12px;
  position: relative;
}
.login-memeber-ul .border-set::before {
  content: "";
  position: absolute;
  width: 208px;
  height: 1px;
  left: -30px;
  background: #dcdcdca8;
  bottom: 0;
}
.login-member-text h2 span {
  font-size: 14px;
}
.login-member-text h2 {
  font-size: 43px;
}
.text-hopp h5 {
  font-size: 8px !important;
}
.login-member-text h5 {
  font-size: 15px;
}
/* .member-text h5{
	font-size: 8px;
} */
.login-member-text h2 .dollar img {
  /* font-size: 14px; */
  width: 15px;
}
.all-login-membership {
  padding: 0;
  border-right: 1px solid #dcdcdc;
}
/* ============== */

/* Begin of forget password Css */
.forget-password-head h2 {
  font-size: 27px;
  color: var(--third-color);
}
/* ======================== */

/* Paination */
.profile-all .pagination_btn ul {
  width: 100% !important;
  justify-content: flex-end !important;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
/* Paination */
.login-sec {
  overflow: hidden;
}

@media (max-width: 1920px) {
  .login-form-text {
    width: 90%;
    right: 0;
    text-align: center;
  }
  .login-form-all {
    width: 36%;
  }
  .register-form-all {
    padding-left: 170px;
  }
}
@media (max-width: 1536px) {
  .login-form-all {
    width: 45%;
  }
  .register-head {
    width: 56%;
  }
}

@media (max-width: 1440px) {
  .register-head {
    width: 60%;
  }
  .register-head p {
    width: 80%;
  }
}

@media (max-width: 1366px) {
  .login-form-all {
    width: 50%;
  }
  .register-form-all {
    padding-left: 130px;
  }
  .register-form-all {
    width: 100% !important;
  }
  .register-head {
    width: 100%;
  }
  .register-head p {
    width: 80%;
  }
  .login-memeber-ul li {
    font-size: 9px;
    position: relative;
  }
  .login-all-text h2 {
    font-size: 28px;
  }
}

@media (max-width: 1280px) {
  .member-btn-login a {
    font-size: 12px;
  }
  .register-head p {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  .login-form-all {
    width: 60%;
  }
  .login-all-text h2 {
    font-size: 20px !important;
    line-height: 25px;
  }
  .my-profile-sec::before {
    width: 79%;

    right: 37px;
  }
  .my-order-view-detail-sec::before {
    right: 51px;
  }
}
@media (max-width: 1024px) {
  .login-form-all {
    width: 65%;
  }
}
@media (max-width: 991px) {
}

@media (max-width: 820px) {
  .login-form-all {
    width: 78%;
  }
}
@media (max-width: 768px) {
  .login-form-all {
    width: 83%;
  }
}
@media (max-width: 767px) {
  .register-form-all {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login-all-text p {
    color: #ffffff;
  }
  .follow-us-on {
    top: 0;
    margin: 30px 0;
  }
  .login-btn {
    margin: 10px 0 !important;
}
}
@media (max-width: 575px) {
  .login-form-all {
    width: 100%;
    padding: 10px;
    padding-top: 90px;
  }
  .remeber-and-forgot.new a {
    font-size: 12px;
  }
}
