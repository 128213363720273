.cart-sec th {
  font-size: 12px;
  padding: 20px 36px;
  text-align: center;
  background-color: var(--third-color);
  color: #ffffff;
  font-family: "hasnon-bold";
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #dcdcdc6b;
}
.cart-sec td {
  font-size: 12px;
  text-align: center;
  font-family: "poppins-meduim";
  color: var(--fourth-color);
  padding: 20px 0px;
  font-family: "poppins-semibold";
  border: 1px solid #dcdcdc47;
}
.body-img img {
  width: 70%;
}
.cart-table {
  width: 100%;
}
.cart-btn {
  width: 100%;
  padding: 14px;
}
.cart-btn a {
  padding: 9px 0px;
  font-size: 12px;
  border-radius: 4px;
}
section.cart-sec {
  padding-bottom: 100px;
}
.order-hotel-all {
  border-top: 1px solid #dcdcdc61;
  border-bottom: 1px solid #dcdcdc6b;
  padding: 9px 0px;
  margin-top: 16px;
}
.body-img {
  position: relative;
}
.body-delete-all {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--first-color);
  padding: 2px 7px;
  color: #ffffff;
}
.coupon-head h5 {
  padding: 0;
}
.coupon-input input {
  width: 54%;
  padding: 12px;
  border-radius: unset;
  position: relative;
  border: unset;
  border-radius: 5px 0px 0px 0px;
}
.product-copun {
  position: relative;
}
.product-copun {
  position: relative;
  margin-top: 0px;
  border-radius: 5px;
  margin-bottom: 24px;
}
.cart-coupon-btn {
  /* position: absolute;
  top: 56px;
  right: 21px;
  width: 42%; */
  border-radius: unset !important;
}

.coupon-head p {
  font-size: 10px;
  color: #707070;
  font-family: "poppins-regular";
}
.body-delete-all i {
  font-size: 9px;
}
.cart-btn img {
  width: 18%;
}

.for-back-color {
  background: #f9f9f9;
}

.quantity-btn-all input {
  width: 28%;
  padding: 1px;
  height: 56px;
  border: unset;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
  outline: unset;
}

.quantity-btn-all button i {
  padding: 20px 14px;
  border: 1px solid #dcdcdc14;
  cursor: pointer;
  outline: 0;
  height: 54px;
}

.quantity-btn-all button {
  border: 1px solid #dcdcdc;
  background-color: unset;
}
.for-total-css {
  font-weight: 400;
}
.cart-coupon-btn button {
  font-size: 14px;
  border-radius: 0px 5px 5px 0px;
  background-color: #f1480c;
  padding: 10px 30px;
  border: none;
  margin: 0;
}

.cart-coupon-btn a {
  font-size: 14px;
  border-radius: 0px 5px 5px 0px;
  background-color: #f1480c;
  padding: 10px 0px;
}

.coupon-input {
  padding: 10px 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
.cart-coupon-btn img {
  width: 27px;
}
.cart-detail-all {
  border-radius: 5px;
}
.c-btn1 {
  padding-right: 0px;
}
.c-btn2 {
  padding-left: 0;
}
.cart-total-all {
  padding: 0;
}

@media (max-width: 1366px) {
  /* ~New */
  .banner-button img {
    left: 2px;
  }
  /* ~New */
  .cart-btn a {
    padding: 9px 0px;
    font-size: 11px;
    border-radius: 4px;
  }
  .cart-coupon-btn a {
    font-size: 12px;
  }
  .cart-coupon-btn button {
    font-size: 12px;
  }
}
@media (max-width: 1280px) {
  .cart-coupon-btn a {
    font-size: 12px;
  }
  .cart-coupon-btn button {
    font-size: 12px;
  }
}
@media (max-width: 1150px) {
  .cart-coupon-btn {
    top: 72px;
  }
  .cart-coupon-btn button {
    font-size: 9px;
    margin: 0 !important;
  }
  .banner-button img {
    display: none;
  }
}
@media (max-width: 991px) {
  .cart-sec th {
    padding: 14px !important;
  }
}
@media (max-width: 767px) {
  .cart-detail-all {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
  .cart-btn {
    padding: 0;
    width: 90%;
  }
  section.cart-sec {
    padding-bottom: 50px;
  }
}
@media (max-width: 575px) {
  .quainty-and-button {
    display: block;
    align-items: baseline;
  }
  .Add-to-cart-on-detail-page {
    width: 100%;
  }
  .all-borders img {
    height: 190px;
    padding: 10px;
    background: #f9f9f9;
  }
  .coupon-head p {
    font-size: 9px;
  }
  .product-copun {
    padding-bottom: 25px;
  }
  .cart-coupon-btn {
    top: 54px;
  }
  /* .c-btn1 {
    padding-right: 14px;
  } */
  /* .c-btn2 {
    padding-left: 14px;
  } */
  .cart-detail-all {
    border-radius: 5px;
    margin-top: 30px;
  }
  .Main-cart-table {
    overflow-y: scroll;
  }
  .coupon-input {
    flex-direction: column;
  }
  .coupon-dv {
    max-width: 100%;
    width: 100%;
  }
  .cart-coupon-btn {
    max-width: 100%;
    width: 100%;
  }
  .coupon-dv button {
    max-width: 100%;
    width: 100%;
  }
}
