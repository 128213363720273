.location-img {
	position: relative;
	margin: 10px 0;
}
.location-text {
	position: absolute;
	bottom: 0;
	padding: 30px;
}
.location-head h3 {
	font-size: 24px;
	color: #ffffff;
	font-family: "hasnon-bold";
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.location-head p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
}
.location-head p {
	font-size: 11px;
	color: #ffffff;
	font-family: "poppins-regular";
	width: 72%;
}
.location-img label {
	font-size: 14px;
	font-family: "poppins-meduim";
	color: #ffffff !important;
}
.for-direct a {
	margin-top: 0px;
}
section.location-sec {
	padding-bottom: 110px;
}
.location-head h3 span img {
	width: 6%;
	position: relative;
	bottom: 3px;
}

@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
	.location-head p {
		font-size: 11px;
		width: 88%;
	}
	/* .three-links {
		left: -105px;
	} */
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
	.for-direct {
		width: 90%;
	}
	.location-head h3 {
		font-size: 15px;
	}
	.location-head p {
		width: 100%;
	}
	.location-head span img {
		width: 10%;
	}
}

@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
	.location-text {
		padding: 30px 10px;
	}
	section.location-sec {
		padding-bottom: 20px !important;
	}
}
