.thank-you-head h3 {
  font-size: 30px;
  color: var(--third-color);
  font-family: hasnon-bold;
  margin: 0;
  text-transform: uppercase;
}
.thank-you-head h4 {
  font-size: 16px;
  color: #262626;
  font-family: "poppins-bold" !important;
  margin: 0;
}

.thank-you-head h5 {
  font-size: 25px;
  color: #d55302;
  font-family: "poppins-regular";
  margin: 0;
}

.thank-you-head {
  text-align: center;
  padding-bottom: 50px;
}

.shippment-heading h3 {
  font-size: 16px;
  color: var(--fourth-color);
  font-family: "hasnon-bold";
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 40px;
}

.for-light {
  font-size: 14px;
  font-family: "poppins-regular";
  color: #000;
}

.inner-stuff {
  display: flex;
  justify-content: initial;
  align-items: center;
  padding: 0px 35px;
}

/* .thank-for-shopping-dv {
    background-color: #F9F9F9;
    padding: 20px 60px;
} */
.thanku-btn {
  width: 45%;
  margin: 0px auto;
}

.for-dark {
  font-size: 16px;
  color: #244638;
  font-family: "poppins-semibold";
}

.for-extra-padding {
  padding-top: 40px !important;
}
.thank-you-sec {
  padding: 100px 0px;
}

/* .first-div {
    padding-top: 70px;
} */
.thank-you-all {
  width: 45%;
  margin: 0 auto;
  border: 1px solid #dcdcdc8a;
}

.inner-stuff p a {
  font-size: 16px;
  color: #244638;
  font-family: "poppins-semibold";
  text-decoration: unset;
}

.inner-all {
  background-color: #f9f9f9;
  padding: 37px 0px;
}

.inner-all .shippment-heading h3 {
  padding: 0px 35px;
}

.inner-stuff .for-light {
  min-width: 110px;
}

.second-div .inner-stuff .for-light {
  min-width: 170px;
}

.inner-stuff-price {
  display: flex;
  justify-content: space-between;
  padding: 0px 35px;
}

.thanku-btn a {
  font-family: "poppins-regular";
}
.inner-stuff-price p {
  min-width: 83px;
}

@media (max-width: 767px) {
  .thank-you-all {
    width: 100%;
  }
  .thank-you-head {
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .thank-you-sec {
    padding: 30px 0px;
  }
  .thank-you-head h3 {
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
  }
  .thank-you-all {
    width: 100%;
  }
  .inner-stuff {
    padding: 0px 14px;
    display: flex;
    justify-content: space-around !important;
  }
  .inner-stuff-price {
    padding: 0px 15px;
    display: flex;
    justify-content: space-around !important;
  }
  .thanku-btn {
    width: 100%;
  }
  .for-dark {
    font-size: 12px;
  }
  .inner-stuff p a {
    font-size: 11px;
  }
  .thank-for-shopping-dv .inner-stuff p,
  .thank-for-shopping-dv .inner-stuff-price p {
    width: 50%;
  }
  .for-extra-padding {
    padding-top: 10px !important;
  }
  .inner-all .shippment-heading h3 {
    padding: 8px 15px;
    line-height: 25px;
    font-size: 14px;
  }
}
