.not__found-wrap {
    max-width: 800px;
    margin: auto;
    padding: 5rem .5rem;
    text-align: center;
}

.not__found-wrap h1 {
    font-family: 'hasnon-bold';
    color: var(--first-color);
}