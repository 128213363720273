.pro-text p {
  font-size: 12px;
  color: var(--fourth-color);
  font-family: "poppins-semibold";
  margin: 0;
}

.pro-price p {
  font-size: 12px;
  color: var(--fourth-color);
  font-family: "poppins-bold";
}

.pro-first-dv {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #dcdcdc66;
  align-items: center;
}

.pro-img {
  display: flex;
  align-items: center;
}

.pro-img img {
  width: 120px;
  height: 70px;
  object-fit: contain;
  margin-right: 10px;
}

.for-over-flow {
  overflow-y: scroll;
  height: 200px;
  padding: 0px 30px;
  position: relative;
  right: 10px;
}
.billin-dv h4 {
  font-size: 22px;
  color: var(--fourth-color);
  font-family: "hasnon-bold";
}

.billin-dv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billin-dv p {
  font-size: 12px;
  color: var(--fourth-color);
  font-family: "poppins-regular";
}

.billin-dv p a {
  color: var(--fourth-color);
  font-family: "poppins-semibold";
  text-decoration: unset;
}

.all-checkout label {
  font-size: 12px;
  font-family: "poppins-meduim";
  color: var(--second-color);
  padding-bottom: 2px;
}

.all-checkout select.form-select.form-select-lg.mb-3 {
  border-radius: unset;
  font-size: 15px;
  color: var(--second-color);
  font-family: "poppins-meduim";
}

.check-out-input input {
  background-color: #ffffff !important;
}

.product-detail-all {
  background-color: #f9f9f9;
  padding-top: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 5px 5px 0px 0px;
}

.product-detail-all .product-head {
  padding: 0px 30px;
}

.product-detail-all .all-total .total.order-hotel-all {
  padding: 11px 20px !important;
}

.product-detail-all .all-total .total.order-hotel-all p {
  margin: 0px;
}

.product-detail-all .all-total .total {
  padding: 0px 17px;
}

.product-head h5 {
  font-size: 16px;
  font-family: "hasnon-bold";
  padding-bottom: 30px;
  color: var(--fourth-color);
}

.summary-dv h4 {
  font-size: 20px;
  font-family: "hasnon-bold";
  color: var(--fourth-color);
  padding-bottom: 20px;
}

.pament-details h5 {
  font-size: 16px;
  font-family: "hasnon-bold";
  color: var(--fourth-color);
  border-bottom: 1px solid #dcdcdc85;
  padding: 0px 30px;
}

.total {
  display: flex;
  justify-content: space-between;
}

.for-total-green {
  font-size: 12px;
  color: var(--fourth-color);
  font-family: "poppins-bold";

  min-width: 63px;
}

.pament-details {
  padding-top: 50px;
  padding-bottom: 30px;
}
.form-dv-input.check-out-input input::placeholder {
  color: #000000;
  font-family: "poppins-meduim";
}
/* .for-over-flow::-webkit-scrollbar {
    width: 5px;
    background-color:  transparent;
}

.for-over-flow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
}

.for-over-flow::-webkit-scrollbar-thumb {

    outline: 1px solid slategrey;
} */
.for-over-flow::-webkit-scrollbar {
  width: 0.3em;
}
.cart-btn a {
  margin-top: 10px;
}
.for-over-flow::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.for-over-flow::-webkit-scrollbar-thumb {
  background-color: var(--third-color);
  /* outline: 1px solid slategrey; */
}

.check-out-sec {
  padding-bottom: 100px;
}

/* .all-checkout {
	width: 90%;
} */

.check-out-input {
  padding: 10px 0px;
}

.ordr-total {
  display: flex;
  justify-content: space-between;
  background-color: var(--third-color);
  padding: 15px 43px;
}

.ordr-total p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.ordr-total p {
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.check-box-row {
  margin-top: 40px;
  border-top: 1px solid #70707042;
  display: flex;
  align-items: center;
}

.checkout-btns a {
  margin: 0;
}

.checkout-btns {
  float: right;
  margin-top: 30px;
}

.check-and {
  padding-top: 30px;
}

.check-box-row .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid #d55302;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.memeber-check a {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-dv-style-select label {
  padding-bottom: 10px;
}

.check-out-input input::placeholder {
  font-size: 12px;
  text-align: end;
  font-family: "poppins-meduim";
  color: #000000;
}

.check-out-input input::placeholder {
  font-size: 13px;
  color: #000000;
  font-family: "poppins-semibold";
}
.all-total {
  padding: 0px 20px;
}
.checkout .add-new-address label {
  border-radius: initial;
  color: var(--second-color);
  font-family: poppins-meduim;
  font-size: 12px;
}

.check-out-sec .address-container .billin-dv {
  padding-left: 60px;
}

.check-out-sec .address-container .checkmark {
  position: relative;
}

.check-out-sec .address-container .checkmark .check-dot {
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  left: 0;
  border-radius: 50%;
  border: 2px solid #1b390f;
  box-shadow: 0px 0px 0px 1px #1b390f;
  top: -57px;
  margin: 20px 20px;
}

.checkout span.checkmark .add-new-address {
  display: none;
}

.checkout input:checked ~ span.checkmark .add-new-address {
  display: block;
}

.check-out-sec .address-container input:checked ~ .checkmark .check-dot {
  background: #000;
  border-color: #fff;
}

.check-out-sec .address-container input:checked ~ .checkmark .check-dot {
  background: #000;
  border-color: #fff;
}

.checkout
  .saved-address
  input:checked
  ~ span.checkmark
  .select-address
  .radio-btn
  span {
  background: #000;
  border-color: #fff;
}

.checkout .add-new-address input {
  font-family: "poppins-meduim";
  font-size: 12px;
}

.checkout .add-new-address .check-and-text label {
  color: var(--second-color);
  font-family: poppins-meduim;
  font-size: 12px;
}

/* ~New */
.coupon-dv .banner-button {
  width: 100%;
}
/* ~New */

@media (max-width: 1280px) {
}

@media (max-width: 1100px) {
}

@media (max-width: 1024px) {
  .cart-coupon-btn {
    top: 59px;
  }
}

@media (max-width: 991px) {
  .my-all-address {
    width: 100% !important;
  }
  .for-over-flow {
    padding: 0px 0px;
  }
}

@media (max-width: 820px) {
}

@media (max-width: 768px) {
  .checkout-btns {
    float: none;
  }
  .cart-coupon-btn .banner-button a,
  .banner-button button {
    margin-top: 42px !important;
  }
  /* .cart-coupon-btn {
	top :0 !important
} */
}

@media (max-width: 575px) {
  .all-checkout {
    width: 100%;
  }

  .billin-dv p {
    font-size: 9px;
  }

  .content-wrapper {
    padding-bottom: 0px;
  }

  .billin-dv h4 {
    font-size: 20px;
    line-height: 20px;
  }
  .all-total {
    padding: 0px 0px;
  }
  .for-over-flow {
    padding: 0px 0px;
  }
  .ordr-total {
    padding: 15px 24px;
  }
  .check-and label {
    font-size: 13px;
  }
  .checkout-btns {
    width: 100%;
  }
  .check-out-sec .address-container .checkmark {
    position: relative;
    top: -32px !important;
  }
  .checkout-btn a {
    text-transform: uppercase;
  }

  .checkout .add-new-address {
    padding-top: 50px !important;
    position: relative;
  }
  .checkout .saved-address .select-address .radio-btn span {
    position: absolute;
    left: 0;
    top: 4px !important;
  }
  /* .. */

  /* .cart-coupon-btn {
    position: absolute;
    top: 48px !important;
    right: 21px !important;
    width: 50% !important;
  } */
  .cart-coupon-btn button {
    font-size: 12px !important;
  }
  /* .cart-coupon-btn {
    top: 47px !important;
  } */

  .coupon-input input {
    width: 100% !important;
    padding: 9px !important;
    border-radius: unset;
    position: relative;
    border: 1px solid;
  }
  .coupon-input {
    padding: 6px 20px !important;
    padding-bottom: 0px !important;
  }

  .check-out-sec {
    padding-bottom: 50px;
  }

  .check-out-sec .address-container .checkmark .check-dot {
    top: -12px;
  }
  .checkout .saved-address .select-address .address-detail h5.last span.value {
    margin-left: 18px !important;
  }
}

@media (max-width: 375px) {
  .checkout .saved-address .select-address .radio-btn span,
  .check-out-sec .address-container .checkmark .check-dot {
    margin: 10px;
  }
  .check-out-sec .address-container .checkmark .check-dot {
    top: -3px;
  }
  .checkout .saved-address .select-address .address-detail {
    padding-left: 6px !important;
  }
  .checkout .saved-address .select-address .radio-btn span {
    top: 14px !important;
  }
  .checkout .saved-address .select-address .address-detail h5 span.value {
    font-size: 12px !important;
    margin-left: 10px;
  }
}
@media (max-width: 325px) {
  .checkout .saved-address .select-address .address-detail .top {
    flex-direction: column;
  }
  .checkout .saved-address .select-address .button-group {
    bottom: 10px;
  }
}
@media (max-width: 290px) {
  .check-out-sec .address-container .checkmark {
    position: relative;
    top: -51px !important;
  }
  .checkout .saved-address .select-address .radio-btn span {
    position: absolute;
    left: 0;
    top: 4px !important;
  }
  .checkout-btn a {
    text-transform: uppercase;
  }

  .checkout .add-new-address {
    padding-top: 50px !important;
    position: relative;
  }
  .checkout .saved-address .select-address .button-group {
    position: absolute;
    bottom: 11px !important;
    right: 6px !important;
  }

  /* .cart-coupon-btn {
    position: absolute;
    top: 56px !important;
    right: 4px !important;
    width: 60% !important;
  }
  .cart-coupon-btn {
    top: 68px !important;
  } */

  .cart-coupon-btn button {
    font-size: 8px !important;
    border-radius: 0px 5px 5px 0px;
    background-color: #f1480c;
    padding: 2px 30px !important;
    border: none;
  }
  .coupon-input input {
    width: 53% !important;
    height: 33px;
    padding: 11px !important;
    border-radius: unset;
    position: relative;
    border: 1px solid;
  }
  .coupon-input {
    padding: 9px 20px !important;
    padding-bottom: 0px !important;
  }

  .check-out-sec {
    padding-bottom: 50px;
  }
}
