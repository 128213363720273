.contact-form-input input {
  width: 100%;
  padding: 10px;
}

.contact-form-textarea textarea {
  height: 200px;
  padding: 16px 19px;
  resize: none;
  border-radius: unset;
  background-color: #f3f3f3;
  border: 1px solid #c3cad9;
  margin: 0px;
  margin-top: 10px;
}

.contact-form-input input {
  width: 100%;
  padding: 10px 20px;
  background-color: #f3f3f3;
  border: 1px solid #c3cad9;
  margin: 10px 0px;
  outline: unset;
}

.contact-form-input input::placeholder {
  font-size: 14px;
  color: #00000061;
  font-family: poppins-light;
}

.contact-form-textarea textarea::placeholder {
  font-size: 14px;
  color: #00000061;
  font-family: poppins-light;
}

.submit-banner-btn {
  width: 100%;
}

.submit-banner-btn a {
  background-color: var(--first-color);
  color: #ffffff;
  text-decoration: unset;
  border-radius: 6px;
  margin-top: 18px;
  display: block;
  font-family: "poppins-meduim";
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: center;
}

section.contact-sec {
  padding: 110px 0px;
}

.contact-img img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

section.contact-sec .row {
  display: flex;
  align-items: center;
}

.contact-img {
  position: relative;
}

.call-now-green-box {
  position: absolute;
  bottom: 0;
  background-color: var(--third-color);
  width: 70%;
  padding: 30px;
}

.call h3 {
  font-size: 20px;
  color: #ffffff;
  font-family: "poppins-bold";
  margin: 0;
  line-height: 30px;
  text-transform: uppercase;
}

.call a {
  display: block;
  font-size: 18px;
  color: #ffffff;
  font-family: "poppins-regular";
  text-decoration: unset;
}

.anchor-edit {
  font-size: 10px !important;
}

.call.opening-hour p {
  font-size: 12px;
  color: #ffff;
  font-family: "poppins-regular";
  margin: 0;
  font-weight: 100;
}

.for-border {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  padding-top: 20px;
}

.call-now-anchor {
  padding-bottom: 30px;
}

.locat {
  padding-top: 20px;
}

.contact-upper-head p {
  padding: 0;
}

.contact-upper-head {
  padding-bottom: 22px;
}

.for-border-and-color {
  box-shadow: 0px 0px 10px #0000001f;
  border: 1px solid #dcdcdc;
}

.contact-sec .col-md-4 {
  padding-left: 0px;
}

.contact-form-all {
  padding-left: 40px;
}

.submit-banner-btn a {
  background-color: #f1480c;
}

@media (max-width: 1280px) {
}

@media (max-width: 1100px) {
  .call h3 {
    font-size: 16px;
  }

  .call a {
    font-size: 13px;
  }

  .call.opening-hour p {
    font-size: 9px;
  }
}

@media (max-width: 1024px) {
  .call-now-green-box {
    padding: 22px;
  }

  section.contact-sec {
    padding: 30px 0px;
    padding-bottom: 90px;
  }
}

@media (max-width: 991px) {
  .call-now-green-box {
    width: 85%;
  }
}

@media (max-width: 820px) {
}

@media (max-width: 768px) {
  /* .three-links {
    left: -85px;
  } */
}

@media (max-width: 767px) {
  .about-banner-text p {
    font-size: 14px !important;
    text-align: center ;
  }
  .about-banner-text.contact-upper-head {
    text-align: center;
}
}
@media (max-width: 575px) {
  /* .about-banner-text h2 {
    font-size: 18px !important;
    line-height: 19px !important;
  } */
  /* .about-banner-text p {
    text-align: justify !important;
  } */

  .contact-upper-head {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .contact-form-all {
    padding-left: 0;
  }

  .contact-img {
    position: relative;
    margin: auto !important;
    display: flex;
    justify-content: center;
  }
}
