.shop-head h5 {
	font-size: 14px;
	color: var(--first-color);
	font-family: "poppins-bold";
	margin: 0;
	line-height: 24px;
}

.shop-head h2 {
	font-size: 27px;
	color: var(--second-color);
	font-family: "hasnon-bold";
	line-height: 37px;
}

.shop-head {
	border-bottom: 1px solid #dcdcdc;
}

.stars i {
	color: #ffcd03;
	padding: 0px 2px;
}

.customer p {
	font-size: 12px;
	color: #00000070;
	font-family: "poppins-meduim";
	margin: 0;
	padding-left: 10px;
	padding-bottom: 2px;
}

.quick-reiview h3 {
	font-size: 16px;
	color: var(--second-color);
	font-family: "hasnon-bold";
	line-height: 26px;
	text-transform: uppercase;
}

.quick-reiview {
	display: flex;
	align-items: baseline;
	margin-bottom: 15px;
	flex-direction: column;
}

.shop-detail-sec .all-shop-cart .para-customer-review {
	height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;
	cursor: pointer;
}

.shop-detail-sec .all-shop-cart .para-customer-review::-webkit-scrollbar {
	width: 0.5em;
}

.shop-detail-sec .all-shop-cart .para-customer-review::-webkit-scrollbar-thumb {
	background-color: #f1480c;
	border-radius: 30px;
}

.sevev-dis {
	display: flex;
}

.sevev-dis
	.variation-input
	.variation-container
	input:checked
	~ span.checkmark
	h4 {
	background: #f1480c;
	border-color: #f1480c;
	color: #fff;
}

.sevev-dis h4 {
	font-size: 12px;
	text-transform: uppercase;
	font-family: "poppins-meduim";
	border: 1px solid #707070;
	width: unset;
	height: unset;
	line-height: 18px;
	border-radius: 60px;
	margin: 0px 7px 2px 0px;
	background-color: #f9f9f9;
	cursor: pointer;
	padding: 2px 6px;
}

/* .all-shop-product-div.shop-inner-dv {
	height: 330px;
} */

.sevev-dis {
	display: flex;
	text-align: center;
	padding-left: 0px;
	justify-content: left;
	text-align: left;
}

.para-customer-review p {
	font-size: 11px;
	color: var(--fourth-color);
	font-family: "poppins-meduim";
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.shop-reviews-all .para-customer-review p {
	display: block;
}

.para-us h5 {
	font-size: 16px;
	color: var(--first-color);
	font-family: "poppins-bold";
	margin: 0;
}
.para-us h5 span {
	font-size: 12px;
	color: var(--fourth-color);
	font-family: "poppins-meduim";
}

.stars {
	padding-top: 20px;
}

.sop-cutomer-reivies .stars {
	font-size: 12px;
	color: #00000070;
	font-family: "poppins-meduim";
	margin: 0;
	padding-bottom: 2px;
}

.sop-cutomer-reivies {
	display: flex;
	align-items: end;
}

.para-us {
	padding-top: 80px;
}

.all-shop-cart {
	padding-top: 30px;
}

section.shop-detail-sec {
	padding-top: 130px;
}

/* Begin of Reviews Css */
.review-para p {
	font-size: 12px;
	color: var;
	color: var(--fourth-color);
	font-family: "poppins-semibold";
}

.tab-text h5 {
	font-size: 16px;
	color: var(--fourth-color);
	font-family: "poppins-bold";
}

.tab-text p {
	font-size: 14px;
	color: var(--fourth-color);
	font-style: italic;
	letter-spacing: 1px;
	margin: 0;
	word-break: break-all;
}

.review-tab-all {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	background-color: #ffffff;
	border: 1px solid #dcdcdc;
	padding: 14px 20px;
	padding-top: 0;
	padding-bottom: 30px;
	flex-wrap: wrap;
}
.review-tab-all .tab-text {
	flex: 0 0 85%;
}
.review-tab-all .reviews-stars {
	flex: 0 0 15%;
	text-align: end;
}
.tabs-alls {
	background-color: #f9f9f9;
	padding: 0px 0px;
	margin: 70px 0px;
}

.all-address-box {
	margin-top: 75px;
}

.reviews-tab-sec .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: var(--bs-nav-pills-link-active-color);
	background-color: var(--third-color);
	border-radius: 0px;
	font-family: "poppins-bold";
	font-size: 12px;
	padding: 18px 30px;
}

.reviews-tab-sec .nav-pills .nav-link {
	background: 0 0;
	border: 0;
	border-radius: var(--bs-nav-pills-border-radius);
	color: var(--fourth-color);
	font-family: "poppins-bold";
	font-size: 12px;
	padding: 18px 30px;
}

.for-margin {
	margin-bottom: 30px;
}

.tab-text h5 span {
	font-size: 12px;
	font-family: "poppins-regular";
}

.input-reviews input {
	width: 100%;
	padding: 8px 28px;
	border: 1px solid #ced4da;
	outline: none;
}

.add-review-btn {
	float: right;
	width: 37%;
	padding-bottom: 40px;
}
.tabs-alls ul {
	border-bottom: 1px solid #dcdcdc;
}

.input-reviews label {
	font-size: 14px;
	color: var(--fourth-color);
	font-family: "poppins-meduim";
	padding-bottom: 10px;
}

.form-text-area label {
	font-size: 14px;
	color: var(--fourth-color);
	font-family: "poppins-meduim";
	padding-bottom: 10px;
}

.form-text-area textarea {
	height: 200px;
	border-radius: unset;
	resize: unset;
	padding: 16px 28px;
}

.add-text {
	padding-bottom: 28px;
}

.form-text-area {
	padding-top: 30px;
}

.input-reviews input::placeholder {
	font-size: 10px;
	font-family: "poppins-regular";
}

.form-text-area textarea::placeholder {
	font-size: 10px;
	font-family: "poppins-regular";
}

.quantity button .cancelbtns {
	cursor: not-allowed;
}

.product_data {
	display: flex;
	align-items: center;
	justify-content: center;
}

.set_rating {
	display: flex;
	flex-direction: column;
}

.add-text h5 {
	font-size: 17px;
	color: var(--fourth-color);
	font-family: "poppins-bold";
}

.add-text p {
	font-size: 16px;
	color: var(--fourth-color);
	font-family: "poppins-regular";
}

.review-form {
	padding-top: 40px;
}

.review-para {
	padding-top: 30px;
}

.description-para p {
	font-size: 12px;
	color: var(--fourth-color);
	font-family: "poppins-regular";
}

/* ============================== */

/* Begin of Featured product Css  */
.featured-products {
	background-color: transparent !important;
}

.featured-products .all-shop-product-div {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 18px #00000014;
	padding: 20px;
	position: relative;
	padding-left: 40px;
	cursor: pointer;
	transition: 0.5s ease-in-out;
}

.featured-products .all-shop-product-div:hover {
	transform: scale(1.1);
}

.t-product p {
	background-color: #00a046;
	border-radius: 30px;
	color: #ffffff;
	padding: 0px 11px;
	text-transform: uppercase;
}

.t-product {
	position: absolute;
	top: 20px;
	right: 20px;
}

.t-sale p {
	background-color: #ef262c;
	color: #ffffff;
	text-transform: uppercase;
	padding: 0px 11px;
	border-radius: 10px;
}

.t-sale {
	position: absolute;
	top: 30px;
	right: 30px;
}

.quainty-and-button {
	display: flex;
	align-items: baseline;
}
.quantity input {
	width: 28%;
	padding: 1px;
	height: 58px;
	border: unset;
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	text-align: center;
	outline: unset;
}

.quantity button i {
	padding: 20px 14px;
	border: 1px solid #dcdcdc14;
	cursor: pointer;
	outline: 0;
	height: 56px;
}

.quantity button {
	border: 1px solid #dcdcdc;
	background-color: unset;
}
.Add-to-cart-on-detail-page a {
	background-color: #f1480c;
	letter-spacing: 2px;
}
.Add-to-cart-on-detail-page {
	width: 40%;
	margin-right: auto;
}
.reviews-all {
	padding: 0px 30px;
}
.description-para {
	padding: 10px 20px;
}
.all-shop-cart h2 span {
	color: var(--first-color);
}
.add-text p span {
	color: var(--first-color);
}
.quantity {
	flex: 0 0 35%;
}
/* ===================== */
.shop-inner-dv {
	background-color: #f9f9f9 !important;
	box-shadow: 0px 0px 0px #ffffff !important;
}
.all-inner-shop-page {
	background-color: #f9f9f9 !important;
}

@media (max-width: 1366px) {
	.all-shop-product-div h2 {
		font-size: 17px;
	}
	.all-shop-product-div p {
		font-size: 10px;
	}
}

@media (max-width: 1280px) {
	.all-shop-product-div h2 {
		font-size: 17px;
	}
}

@media (max-width: 1150px) {
	.all-shop-product-div h2 {
		font-size: 14px;
	}

	.all-shop-product-div p {
		font-size: 14px;
	}

	.sevev-display h4 {
		width: 38%;
	}

	.Link-all a {
		font-size: 13px;
	}
	.Link-all .setColor {
		font-size: 13px;
	}

	.Link-all .setColor {
		font-size: 13px;
	}
	.shop-category-ul li a {
		font-size: 12px;
	}

	.shop-inner-page-sec {
		padding-bottom: 80px !important;
	}

	.smoke-shop-section .row {
		display: flex;
		align-items: flex-start;
	}

	section.pageheader {
		padding-bottom: 0px;
	}

	.shop-cate-div h3 {
		font-size: 14px;
	}

	.sevev-display h4 {
		width: 50%;
	}

	.sevev-display h4 {
		font-size: 12px;
	}
	.featured-products .all-shop-product-div {
		padding: 8px 0;
	}

	.all-shop-product-div h5 {
		font-size: 12px;
		line-height: 16px;
		margin: 10px 0;
	}
	.Link-all a {
		font-size: 13px;
	}

	.shop-category-ul li {
		padding: 10px 4px;
	}

	.for-link-flex p {
		font-size: 9px;
		background-color: #ef262c;
		padding: 0 4px;
		margin-left: 9px;
		border-radius: 30px;
	}

	.shop-category-ul li a span {
		padding-right: 1px;
	}
	.featured-products .all-shop-product-div {
		padding: 8px 10px;
	}
	.all-borders img {
		width: 100%;
	}
	.Add-to-cart-on-detail-page {
		width: 50%;
	}
	.tab-text p {
		font-size: 12px;
	}
	.reviews-all {
		padding: 28px 30px;
	}
	.quantity input {
		height: 57px;
	}
	.cart-sec th {
		padding: 20px 29px;
	}

	.cart-coupon-btn {
		top: 72px;
	}
	.cart-coupon-btn a {
		font-size: 9px;
	}

	/* ~ New  */
	/* .featured-products .all-shop-product-div {
		padding: 10px;
		padding-left: 0px;
	} */
	/* ~ New  */
}

@media (max-width: 1024px) {
	/* ~New  */

	.para-us {
		padding-top: 0;
	}
	/* Shop detail */
	.on-small-border {
		height: 80px !important;
		width: 100px;
	}
	section.shop-detail-sec {
		padding-top: 50px;
	}
	/* Shop detail */
	/* ~New  */
}

@media (max-width: 991px) {
	/* ~New  */

	.quainty-and-button {
		flex-direction: column;
	}
	.login-btn .submitButton {
		font-size: 12px;
	}
	.shop-head h2 {
		font-size: 22px;
	}

	/* ~New  */
	.all-shop-product-div.shop-inner-dv {
		height: auto;
	}
	.Link-all a {
		font-size: 11px;
	}
	.Link-all .setColor {
		font-size: 14px;
	}

	.shop-category-ul li {
		padding: 10px 5px;
	}

	.for-link-flex p {
		font-size: 9px;
		background-color: #ef262c;
		padding: 0 4px;
		margin-left: 9px;
		border-radius: 30px;
	}

	.shop-category-ul li a span {
		padding-right: 1px;
	}

	.review-tab-all .reviews-stars {
		flex: 0 0 100%;
		text-align: end;
	}
}

@media (max-width: 820px) {
}

@media (max-width: 768px) {
	/* ~New  */
	.Add-to-cart-on-detail-page {
		margin: 0 !important;
	}
	.all-shop-product-div h5 {
		font-size: 12px;
		line-height: 16px !important;
	}
	.all-shop-product-div p {
		margin: 5px 0;
	}
	.Link-all .setColor {
		font-size: 12px;
		margin: 10px 0;
	}
	/* ~New  */
}

@media (max-width: 575px) {
	.all-shop-product-div.shop-inner-dv {
		height: unset;
	}
	.all-shop-product-div.shop-inner-dv .shop-img img {
		height: unset;
		width: 100%;
		object-fit: cover;
	}
	.shop-img img {
		width: 100%;
		margin-bottom: 20px;
		height: 200px;
		object-fit: cover;
	}

	.all-shop-product-div h2 {
		font-size: 16px !important;
	}

	.all-shop-product-div h5 {
		font-size: 16x;
	}

	.all-shop-product-div p {
		font-size: 13px;
	}

	.all-shop-product-div {
		text-align: center;
	}

	.Link-all a {
		font-size: 18px;
	}

	.Link-all button {
		font-size: 18px;
	}
	/* .shop-category-ul li a {
    font-size: 12px;
  } */

	span.style-module_starRatingWrap__q-lJC {
		transform: translateY(-8px);
	}

	.review-img img {
		height: 150px !important;
		object-fit: contain !important;
	}

	.form-text-area label,
	.input-reviews label {
		font-size: 14px;
		padding-top: 10px;
	}

	section.pageheader {
		padding-bottom: 20px;
	}

	.shop-category-ul li a span {
		padding-right: 12px !important;
	}
	.shop-category-ul li {
		padding: 10px 10px;
	}
	.about-img img {
		width: 100%;
	}
	.about-icon-img {
		top: 39px;
		right: 23px;
	}
	.reviews-all {
		padding: 0px 10px;
	}
	.tab-text p {
		font-size: 10px;
	}
	.review-tab-all {
		padding: 14px 10px;
	}
	.add-review-btn {
		float: right;
		width: 100%;
		padding-bottom: 40px;
	}
	.input-reviews input {
		padding: 8px 8px;
	}
	.add-text p {
		font-size: 12px;
	}
	.on-small-border {
		width: 100%;
		height: 68px !important;
		padding: 13px;
	}
	.quick-reiview h3 {
		font-size: 11px;
	}
	.quainty-and-button {
		display: block;
	}
	.Add-to-cart-on-detail-page {
		width: 100%;
	}
	.para-us {
		padding-top: 10px;
	}
	section.shop-detail-sec {
		padding-top: 20px !important;
	}

	.shop-head h2 {
		font-size: 20px !important;
	}

	.para-customer-review p {
		font-size: 13px !important;
	}
	.description-para {
		padding: 10px 10px !important;
	}
	.description-para p {
		font-size: 13px !important;
	}
	.Link-all .setColor {
		font-size: 13px !important;
	}
	.all-shop-product-div h5 {
		font-size: 12px !important;
	}
	.all-address-box {
		margin-top: 5px !important;
	}
	.form-text-area {
		padding-top: 0px;
	}
	.tabs-alls {
		margin: 50px 0 20px;
	}
}
