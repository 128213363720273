/* Font */
@font-face {
  font-family: "hasnon-bold";
  src: url("../fonts/Hanson-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-meduim";
  src: url("../fonts/Poppins-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "stretch-pro";
  src: url("../fonts/StretchPro.otf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
  font-display: swap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ======================================== */

/* color */
:root {
  --first-color: #d55302;
  --second-color: #02272f;
  --third-color: #1b390f;
  --fourth-color: #000000;
}

body {
  overflow-x: hidden;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "'hasnon-bold'";
}

.Membershipdata {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.paraghraf_set p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.center_thistext {
  text-align: center;
  margin: 4px 0 110px;
  font-family: "hasnon-bold";
  text-transform: uppercase;
  font-size: 40px;
}

p {
  font-family: "poppins-regular";
  font-size: 14px;
}

h2 {
  font-size: 72px;
  font-weight: 600;
  line-height: 82px;
}

h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 52px;
}

h4 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
}

h5 {
  font-size: 20px;
  line-height: 30px;
}

figure {
  margin-bottom: 0;
}

/* Mobile Header Css Start Here */
header .canvas_btn {
  display: none;
  justify-content: end;
  padding: 0 10px 0 0px;
}

#headerTop .right-menus .canvas_btn i {
  color: #fff;
  font-size: 25px;
}

.mobile_header {
  position: fixed;
  z-index: 2;
  right: 0;
  background: #ffffff;
  width: 34%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 30px;
  transition: 0.8s;
  transform: translateX(120%);
  top: 0;
  z-index: 99999;
  overflow: scroll;
}

.mobile_header.show {
  transform: translateX(0%);
  padding-left: 10px;
}

a.cart__wrapper {
  position: relative;
  color: transparent;
}

.mobile_header .cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_menus .menu-items {
  border-bottom: 1px solid #dcdcdc99;
  padding: 5px 0px;
  padding-right: 160px;
}

.mobile_header ul.mobile_menus {
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100vh;
}

.mobile_header ul.mobile_menus li.menu_items {
  margin: 10px 0;
}

.mobile_header ul.mobile_menus li.menu_items.menu_btn {
  margin-top: 30px;
}

.mobile_header ul.mobile_menus li.menu_items a {
  color: var(--second-color);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
}

.mobile_header ul.mobile_menus button.accordion-button {
  padding: 0;
  margin: 0;
  color: #fff;
  background: #000 !important;
  box-shadow: none;
  outline: none;
}

.mobile_header ul.mobile_menus .accordion-item {
  border: 0;
  background: transparent;
}

.mobile_header ul.mobile_menus .accordion-item h2 {
  line-height: unset;
}

.mobile_header ul.mobile_menus button.accordion-button::after {
  background-position-x: 20px;
  width: 40px;
  filter: brightness(0) invert(1);
  height: 40px;
  background-position-y: 15px;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body {
  padding: 0;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body ul {
  padding: 0;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body ul li {
  margin: 10px 0;
}

.plant-img.responsive-plant-img {
  text-align: center;
}

/* .mobile_header ul.mobile_menus li.menu_items.menu_btn a {
  background: #c98536;
  padding: 13px 25px;
  border-radius: 50px;
} */
.header-sec .search a {
  color: transparent;
}

ul.mobile_menus li a {
  font-size: 12px;
  font-family: "poppins-regular";
  text-decoration: unset;
  color: #707070;
  font-weight: 800;
}

@media (max-width: 991px) {
  header .canvas_btn {
    display: flex;
  }
}

@media (max-width: 768px) {
  .mobile_header {
    width: 40%;
  }

  .mobile_menus .menu-items {
    text-transform: uppercase !important;
  }

  .search input {
    padding: 10px 38px !important;
  }
}

@media (max-width: 575px) {
  .mobile_header {
    width: 50%;
  }
}

.responsive-plant-img img {
  width: 80%;
  margin: 0 auto;
}

/* Mobile Header Css Start Here */

/* Begin of Header Css */
.nav-home ul li a {
  font-size: 9px;
  font-family: "poppins-semibold";
  color: var(--fourth-color);
  text-transform: uppercase !important;
  text-decoration: unset;
}

.nav-home ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  align-items: center;
  text-transform: uppercase !important;
}

.nav-home ul li.active {
  border-right: 0px;
}

/* .nav-home ul li {
  flex: 0 0 12.5%;
  text-align: center;
  border-right: 1px solid #f9f9f9;
  align-self: center;
} */

.nav-home ul li {
  flex: auto;
  text-align: center;
  border-right: 1px solid #ede2e2;
  align-self: center;
}

.web-logo img {
  width: 135px;
}

/* .footer-web-logo img {
  min-width: 89px;
} */
.footer-web-logo img {
  width: 135px;
}

.web-logo {
  text-align: center;
}

.search input {
  background-color: #f9f9f9;
  border: none;
  padding: 10px 20px 10px 40px;
  border-radius: 40px;
  position: relative;
  outline: none;
  max-width: 210px;
}

.search {
  position: relative;
}

.search i {
  font-size: 15px;
  position: absolute;
  left: 19px;
  top: 14px;
  cursor: pointer;
}

.search .endIcons {
  /* position: absolute; */
  cursor: pointer;
  left: 153px;
}

.search input::placeholder {
  font-size: 12px;
  color: #0000003d;
  position: relative;
}

.search img {
  position: relative;
  right: 46px;
  bottom: 4px;
  border-left: 1px solid #00000017;
  padding: 0px 10px;
}

.profile-text h4 {
  font-size: 10px;
  line-height: 0px;
  font-family: poppins-regular;
  margin: 0;
}

.all-for-flex {
  display: flex;
}

.profile-text h5 {
  font-size: 15px;
  font-family: poppins-bold;
  margin: 0;
}

.profile-on-header {
  display: flex;
  margin: 0;
  align-items: center;
}

.header-sec .profile-on-header a {
  font-family: "poppins-meduim";
  font-size: 11px;
  text-transform: uppercase;
  border-right: 1px solid #ddd;
  padding: 0px 10px;
  border-left: 1px solid #ddd;
  font-weight: 600;
}

.header-sec .profile-on-header .logoutButton {
  font-family: "poppins-meduim";
  font-size: 11px;
  text-transform: uppercase;
  border-right: 1px solid #ddd;
  padding: 0px 10px;
  border-left: 1px solid #ddd;
  font-weight: 600;
  border-top: 0px;
  border-bottom: 0px;
  background: none;
}

section.header-sec .row {
  display: flex;
  align-items: center;
}

.all-for-flex {
  display: flex;
  align-items: center;
}

.profile-img img {
  width: 100%;
  position: relative;
  top: 7px;
  left: 8px;
}

.header-sec a.active {
  background-color: red;
  background-color: var(--first-color);
  padding: 24px 7px;
  color: #ffffff !important;
  margin: -3px 0px;
  width: 93%;
  display: block;
  margin: 0px auto;
  font-family: "poppins-regular";
}

.nav-home ul li :hover {
  /* background-color: red; */
  background-color: var(--first-color);
  padding: 24px 7px;
  color: #ffffff !important;
  margin: -3px 0px;
  width: 93%;
  display: block;
  margin: 0px auto;
  font-family: "poppins-regular";
}

.content-wrapper h5 {
  font-size: 16px;
  line-height: 0px;
  color: var(--first-color);
  font-family: "poppins-bold";
  margin: 0;
  letter-spacing: 3px;
}

.content-wrapper h2 {
  font-size: 40px;
  color: var(--second-color);
  font-family: "hasnon-bold";
  text-transform: uppercase;
}

section.pageheader {
  text-align: center;
  padding: 70px;
}

.content-wrapper h2 span {
  color: var(--first-color);
}

.profile-on-header a {
  color: var(--fourth-color);
  text-decoration: unset;
}

.profile-on-header .logoutButton {
  color: var(--fourth-color);
  text-decoration: unset;
}

.canvas_btn {
  display: none;
}

.shopping-bag-icon p {
  font-size: 9px;
  width: 15px;
  height: 15px;
  height: absolute;
  position: absolute;
  top: 12px;
  right: 44px;
  background: var(--first-color);
  color: #ffffff;
  border-radius: 50px;
  padding: 0px;
  text-align: center;
  line-height: 13px;
  margin: 0;
  border: 1px solid #ffffff;
}
.mobile_header .shopping-bag-icon p {
  top: -10px;
  right: 0;
}

/* ====================================== */

/* Begin of Banner section  */
.banner-text h5 {
  font-size: 12px;
  font-family: "poppins-bold";
  color: var(--first-color);
  margin: 0;
  line-height: 0;
  letter-spacing: 3px !important;
}

.banner-text h2 {
  font-size: 35px;
  font-family: hasnon-bold;
  color: var(--second-color);
  line-height: 35px;
  text-transform: uppercase;
  padding: 10px 0px;
  margin: 0;
}

.banner-text h4 {
  font-size: 14px;
  font-family: "poppins-bold";
  color: var(--second-color);
  line-height: 0px;
  padding: 10px 0px;
}

section.banner-sec {
  padding-top: 100px;
  position: relative;
  padding-bottom: 190px;
  overflow: hidden;
}

.video-on-home .video-icon .modal-btn {
  border: 0;
  outline: none;
  box-shadow: none;
}

div#VideoModal .modal-content {
  background: transparent;
  border: 0;
  box-shadow: none;
}

div#VideoModal .modal-content .btn-close {
  position: absolute;
  right: 25px;
  color: #fff !important;
  opacity: 1;
  filter: brightness(0) invert(1);
  top: 25px;
  z-index: 1;
}

div#VideoModal .modal-content .modal-body > div {
  height: auto !important;
}

.banner-text p {
  font-size: 12px;
  font-family: "poppins-semibold";
  color: var(--fourth-color);
  margin: 0;
  width: 84%;
}

.banner-text p span {
  color: var(--first-color);
  font-family: "poppins-semibold";
}

.banner-text h2 span {
  color: var(--first-color);
}

.banner-button a,
.banner-button button {
  background-color: var(--first-color);
  color: #ffffff;
  text-decoration: unset;
  border-radius: 6px;
  margin-top: 38px;
  display: block;
  font-family: "poppins-regular";
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
}

.subscribe-btn button.all-button {
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  outline: none;
}

.banner-button {
  width: 50%;
  text-align: center;
}

.banner-button img {
  position: relative;
  left: 14px;
}

.background-video video {
  bottom: 0;
  height: 100% !important;
  left: 116px;
  position: absolute;
  right: 0;
  top: 0;
  width: 92%;
  z-index: -1;
  object-fit: cover;
}

.for-slightly-moving {
  position: relative;
  right: 90px;
}

.text-double {
  font-size: 14px;
  width: 68%;
  margin: 0 auto;
  font-family: "poppins-regular";
  color: #00000070;
}

.text-sigle {
  font-size: 17px;
  width: 46%;
  margin: 0 auto;
  font-family: "poppins-regular";
  color: #00000070;
}

.forum-img {
  position: relative;
}

.rating-start {
  position: absolute;
  top: 10px;
  right: 0px;
}

.rating-start h4 {
  font-size: 13px;
  background: var(--second-color);
  color: #ffffff;
  /* padding: 10px; */
  border-radius: 60px;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 2px solid #ffffff;
  line-height: 48px;
}

.rating-start h4 span i {
  color: #ffcf00;
}

.only-for-banner-btn a {
  letter-spacing: 4px;
}

.side-move-pic img {
  width: 40px;
}

.side-move-pic {
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 1;
}

.marquee {
  top: 6em;
  position: relative;
  animation: marquee 3s linear infinite;
}

.marquee:hover {
  animation-play-state: paused;
}

/* Make it move! */
@keyframes marquee {
  0% {
    top: 0em;
  }

  100% {
    top: -11em;
  }
}

/* ======================================= */

/* Begin of Banner About Section css */
.about-img img {
  width: 75%;
  position: relative;
}

.about-banner-text {
  position: relative;
  z-index: 1;
}

.about-banner-text h5 {
  font-size: 14px;
  font-family: "poppins-bold";
  color: var(--first-color);
  margin: 0;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 3px !important;
}

.about-banner-text h2 {
  font-size: 35px;
  font-family: hasnon-bold;
  color: var(--second-color);
  line-height: 46px;
  text-transform: uppercase;
  padding: 10px 0px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.about-banner-text h2 span {
  color: var(--first-color);
}

.about-banner-text p {
  font-size: 13px;
  font-family: "poppins-meduim";
  color: var(--fourth-color);
  margin: 0;
  padding: 10px 0px;
}

.about-banner-text p span {
  color: var(--first-color);
  font-family: "poppins-bold";
  font-size: 12px;
}

section.banner-about-sec .row {
  display: flex;
  align-items: end;
}

.about-main-home-banner .row {
  display: flex;
  align-items: center !important;
}

section.banner-about-sec {
  padding-top: 80px;
  padding-bottom: 40px;
}

.img-1 img {
  width: 40%;
}

.img-1 {
  background-color: var(--third-color);
  color: #ffffff;
  width: 64%;
  text-align: center;
  border-radius: 72px;
  height: 89px;
  padding: 0;
  z-index: 1;
  position: relative;
  border: 7px solid #0000000f;
  box-shadow: 0px 0px 0px 7px #0000003b;
}

.img-1 h5 {
  font-size: 12px;
  font-family: "hasnon-bold";
  line-height: 15px;
}

.about-img {
  position: relative;
}

.about-icon-img {
  position: absolute;
  top: 39px;
  right: 93px;
}

.about-icon-img2 {
  position: absolute;
  bottom: 83px;
  left: 20px;
}

.img-2 img {
  width: 40%;
}

.img-2 {
  background-color: var(--third-color);
  color: #ffffff;
  width: 57%;
  text-align: center;
  border-radius: 72px;
  height: 92px;
  padding: 1px;
  z-index: 1;
  position: relative;
  border: 7px solid #0000000f;
  box-shadow: 0px 0px 0px 7px #0000003b;
}

.img-2 h5 {
  font-size: 12px;
  font-family: "hasnon-bold";
  line-height: 15px;
}

.all-forum-img {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.forum-text h3 {
  font-size: 16px;
  color: var(--third-color);
  font-family: "hasnon-bold";
  line-height: 20px;
  margin: 0;
  max-width: 251px;
  text-transform: uppercase;
}

.forum-text h4 {
  font-size: 14px;
  color: var(--first-color);
  font-family: "poppins-bold";
  margin: 0;
}

/* .forum-img img {
	margin-bottom: 11px;
	width: auto;
} */

.forum-img img {
  margin-bottom: 11px;
  width: 150px;
}

.video-text h5 {
  font-size: 16px;
  color: var(--fourth-color);
  font-family: "hasnon-bold";
  line-height: 28px;
  margin: 0;
  text-transform: capitalize;
}

.video-on-home .video-icon .modal-btn {
  border: 0;
  outline: none;
  box-shadow: none;
}

.video-text p {
  font-size: 11px;
  color: var(--fourth-color);
  font-family: "poppins-semibold";
  width: 60%;
  margin-bottom: 0;
}

.video-on-home {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
}

.video-text {
  padding-left: 50px;
}

.video-img i {
  background-color: var(--first-color);
  padding: 16px 18px;
  border-radius: 60px;
  color: #ffffff;
}

.play-border {
  border: 3px solid #ffffff;
  position: absolute;
  border-radius: 40px;
  padding: 5px;
  top: 30px;
  right: -30px;
}

.video-img {
  position: relative;
}

.three-links a {
  font-size: 10px;
  color: var(--fourth-color);
  font-family: "poppins-regular";
  text-decoration: unset;
  padding: 0px 10px;
}

.three-links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% - 80px);
}
.three-links a {
  transform: rotate(-90deg);
  display: block;
  margin: 50px 10px;
}

.about-ban-sec .row {
  align-items: center !important;
}

.btn-for-about-ban {
  width: 36%;
}

.for-gap-in-words {
  padding: 0px 6px;
}

.home-about-banner-btn {
  width: 36%;
  margin: 0px 0px 30px auto;
}

.home-about-banner-responsive .home-about-banner-btn {
  margin: 0;
}

/* =================================== */

/* Begin of Smoke shop section css */
.smoke-shop-section
  .all-shop-product-div
  .sevev-dis
  .variation-container:first-child
  span.checkmark
  h4 {
  margin-left: 0;
}

.smoke-shop-section .shop-inner-page-sec {
  padding-bottom: 0 !important;
}

.smoke-shop-section .all-shop-product-div .sevev-dis {
  padding: 0;
}

section.smoke-shop-section {
  background-color: #fbfbfb !important;
  padding: 50px 50px 50px !important;
  position: relative;
}

.pagination-wrapper ul.pagination li.page-item a {
  border-radius: 0 !important;
  margin: 0px 2px;
  color: #d55302;
  font-family: "poppins-meduim";
  font-size: 14px;
}

.pagination-wrapper ul.pagination li.page-item.active a {
  background: #d55302;
  border-color: #d55302;
  color: #fff;
}

.shop-banner-text p {
  padding: 0 !important;
  width: 44%;
}

.shop-category-ul {
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 0px 10px #0000000d;
}

.shop-category-ul li a {
  font-size: 13px;
  color: var(--fourth-color);
  font-family: "poppins-semibold";
  text-decoration: unset;
  /* background: #f9f9f9; */
}

.shop-category-ul li a:hover {
  color: var(--first-color);
}

/* .shop-category-ul li .activesdsdsd {
		color: var(--third-color);
		font-size: 16px;
		font-weight: 500;
	} */
.shop-category-ul li .activesdsdsd {
  color: var(--third-color);
  font-size: 14px;
  font-weight: 500;
}

.shop-category-ul li a span {
  padding-right: 11px;
}

.for-link-flex {
  display: flex;
  align-items: baseline;
}

.for-link-flex p {
  margin: 0;
}

.shop-category-ul li {
  padding: 22px;
  border-bottom: 1px solid #0000000f;
  position: relative;
}

.for-link-flex p {
  font-size: 10px;
  background-color: #ef262c;
  padding: 2px 8px;
  margin-left: 20px;
  color: #ffffff;
  border-radius: 30px;
}

.all-shop-product-div h2 {
  font-size: 20px;
  color: var(--fourth-color);
  line-height: 26px;
  font-family: "hasnon-bold";
}

.all-shop-product-div p {
  font-size: 11px;
  margin: 0;
  font-family: "poppins-regular";
  color: var(--fourth-color);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.all-shop-product-div h5 {
  font-size: 16px;
  color: var(--first-color);
  font-family: "poppins-bold";
  margin: 0;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.small-data {
  font-size: 10px;
  color: var(--fourth-color);
  font-family: "poppins-meduim";
  padding-left: 10px;
}

.sevev-display h4 {
  font-size: 13px;
  line-height: 12px;
  background-color: #fbfbfb;
  border: 1px solid #707070;
  padding: 3px;
  width: 23%;
  font-family: "poppins-meduim";
  text-align: center;
  border-radius: 41px;
}

.sevev-display {
  display: flex;
  padding: 10px 0px;
}

.given-margin {
  margin-right: 10px;
}

.shop-img img {
  width: 100%;
  margin-bottom: 20px;
  height: 140px;
  object-fit: cover;
}

.Link-all a {
  font-size: 16px;
  color: var(--fourth-color);
  text-decoration: unset;
  font-family: "poppins-meduim";
  letter-spacing: 2px;
}

.Link-all .setColor {
  font-size: 16px;
  color: var(--fourth-color);
  text-decoration: unset;
  font-family: "poppins-meduim";
  letter-spacing: 2px;
  border: none;
  background: none;
}

.Link-all img {
  padding-left: 20px;
}

.all-shop-product-div {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 10px #0000000d;
  border-radius: 10px;
  /* margin: 0px 4px; */
  margin: 10px 0px;
}

.shop-banner-text {
  padding-bottom: 50px;
}

.become-member-head {
  line-height: 1px;
  text-align: center;
  padding-bottom: 50px;
}

.become-member-head h2 {
  padding: 0;
}

.grass-img {
  position: absolute;
  left: -13px;
  top: 0;
}

.grass-img img {
  width: 74%;
}

.shop-cate-div h3 {
  font-size: 22px;
  font-family: "hasnon-bold";
  line-height: 0;
  letter-spacing: 1px;
}

.shop-cate-div {
  background-color: var(--third-color);
  padding: 20px 10px;
  color: #ffffff;
  padding-top: 28px;
}

.shop-inner-page-sec {
  padding: 0 !important;
  padding-bottom: 120px !important;
}

/* ================================ */

/* Begin of Become member section */
.first-dv {
  background-image: url(../img/member.png);
  /* height: 100vh; */
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  /* padding: 40px 1px; */
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 15px;
}

.order-zaza-all-stuff img {
  width: 30%;
  margin: 0 auto;
}

.order-zaza-head h2 {
  font-size: 50px;
  color: var(--second-color);
  font-family: "hasnon-bold";
  text-transform: uppercase;
  margin: 0;
}

.order-zaza-head p {
  font-size: 13px;
  color: var(--first-color);
  font-family: "poppins-bold";
  margin: 0;
}

.btn-TEXTHANDLE button {
  padding: 9px !important;
  font-size: 12px !important;
}
.btn-TEXTHANDLE button .arrow-images {
  left: 10px !important;
  width: 35px !important;
}

.order-zaza-sec {
  text-align: center;
  padding-top: 40px;
}

.first-dv h5 {
  font-size: 16px;
  color: var(--first-color);
  font-family: stretch-pro;
  margin: 0;
  line-height: inherit;
  letter-spacing: 2px;
  /* padding-right: 34px; */
  text-transform: uppercase;
  padding: 0 4px;
}

.first-dv h2 {
  font-size: 58px;
  color: #ffffff;
  font-family: "poppins-bold";
  line-height: inherit;
}

.first-dv h2 span {
  font-size: 20px;
  font-family: "poppins-regular";
  font-weight: 100;
  letter-spacing: 1px;
}

.dollar {
  font-size: 35px !important;
}

.member-text {
  /* position: absolute; */
  position: relative;
  left: 3px;
  /* top: 10px; */
}

.member-ul {
  padding: 0;
  list-style: none;
  margin: 0;
  padding-left: 40px;
  padding-top: 40px;
}

.member-ul li {
  font-size: 15px;
  color: var(--fourth-color);
  font-family: "poppins-semibold";
  padding: 12px 0px;
}

/* .all-become-member-div {
  box-shadow: 0px 8px 4px #0000000d;
  padding-bottom: 30px;
  border: 1px solid #dcdcdcd6;
} */
.all-become-member-div {
  padding-bottom: 30px;
  box-shadow: 0 16px 26px -21px #000000ad;
}

/* .all-become-member-div .become-member-ul {
  padding: 0px 30px;
} */

.member-btn {
  width: 70%;
  margin: 0px auto;
}

.all-become-member-div .become-member-ul .paragraf_member {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: left;
  overflow-y: auto;
  max-height: 180px;
  height: 100%;
}

.all-become-member-div .become-member-ul .paragraf_member p {
  display: flex;
  align-items: start;
  gap: 3px;
}

.all-become-member-div .become-member-ul .paragraf_member::-webkit-scrollbar {
  width: 0.4em;
}

.all-become-member-div
  .become-member-ul
  .paragraf_member::-webkit-scrollbar-track {
  background: transparent;
}

.all-become-member-div
  .become-member-ul
  .paragraf_member::-webkit-scrollbar-thumb {
  background: var(--first-color);
  border-radius: 30px;
}

.set_membership_details .all-become-member-div {
  height: 500px;
  margin-top: 10px;
}

.all-become-member-div .become-member-ul {
  padding: 10px 40px 0;
  text-align: center;
  position: relative;
  top: 4px;
}

.all-become-member-div .become-member-ul .member_btns {
  position: absolute;
  top: 194px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 90%;
}

.member-ul li span {
  padding-right: 20px;
}

section.become-member-sec {
  padding: 80px;
}

.member-ship-sec {
  padding-top: 0px !important;
}

.dollar img {
  width: 24px;
  position: relative;
  bottom: 6px;
  right: 5px;
}

/* ============================ */

/* Begin of Spin section Css */
.spin-btn {
  width: 21%;
}

.spin-btn a {
  letter-spacing: 4px;
}

section.spin-sec .row {
  background-image: url(../img/spining.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 334px;
  padding: 30px 50px 50px;
  margin: 10px 0;
}

.spin-div h2 {
  font-size: 32px;
  color: #ffffff;
  width: 43%;
  line-height: 33px;
  padding-top: 6px;
}

.spin-div p {
  font-size: 17px;
  color: #ffffff;
  width: 48%;
  padding-top: 20px;
  margin-top: 12px;
}

.gallery-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.gallery-all {
  position: relative;
}

.small-arrow-icon {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.small-arrow-img {
  position: absolute;
  bottom: 20px;
  left: 30px;
}

.small-arrow-img {
  position: absolute;
  bottom: 20px;
  left: 30px;
  border: 1px solid var(--first-color);
  padding: 10px 15px;
  cursor: pointer;
  transition: 1s;
}

.small-arrow-img:hover {
  border: 1px solid #ffffff;
}

.galler-head {
  padding-top: 60px;
  padding-bottom: 10px;
}

section.gallery-sec {
  padding-bottom: 100px;
}

.gallery-sec .gallery_box {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.gallery-sec .gallery_box .gallery-all:nth-child(1) {
  flex: 0 0 49%;
}

.gallery-sec .gallery_box .gallery-all {
  flex: 0 0 23.6%;
}

.member-btn a {
  letter-spacing: 4px;
}

/* ======================= */

/* Begin of Newsletter Css */
.newsletter-input input {
  width: 100%;
  padding: 20px 40px;
  box-shadow: 0px 0px 12px #0000001f;
  border: none;
  border-radius: 60px;
  margin: 0 auto;
  outline: none;
}

.newsletter-input {
  text-align: center;
}

.subscribe {
  position: relative;
  max-width: 60%;
  margin: 0px auto;
}

.subscribe-btn {
  position: absolute;
  top: unset;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 28%;
}

.subscribe-btn a,
.subscribe-btn button {
  border-radius: 50px;
  padding: 11px;
  margin: 0;
}

section.newsletter-sec {
  padding: 50px 0px;
  padding-bottom: 70px;
}

section.newsletter-sec h5 {
  padding-bottom: 2px;
}

.newsletter-input input::placeholder {
  font-size: 14px;
  color: #9f9f9f;
  font-family: "poppins-regular";
}

.subscribe-btn a,
.subscribe-btn button {
  font-size: 12px;
}

.subscribe-head h2 {
  color: var(--fourth-color);
}

/* ====================== */

/* Begin of Footer css  */
footer {
  background-color: var(--third-color);
  padding-top: 30px;
}

.footer-links a {
  display: block;
}

.footer-anchor a {
  font-size: 13px;
  color: #ffffff;
  font-family: "poppins-regular";
  text-decoration: unset;
  text-transform: capitalize;
}

footer .row {
  display: flex;
  align-items: baseline;
}

.footer-web-logo {
  position: relative;
  top: 22px;
  text-align: center;
}

.footer-address p {
  font-size: 13px;
  color: #ffffff;
  font-family: "poppins-regular";
  text-decoration: unset;
  margin: 0;
  padding-bottom: 7px;
}

.footer-links a {
  font-size: 13px;
  color: #ffffff;
  font-family: "poppins-regular";
  text-decoration: unset;
  padding-bottom: 6px;
  transition: 1s;
  text-transform: capitalize;
}

.copy-right p {
  font-size: 12px;
  color: #ffffff;
  font-family: poppins-light;
  margin: 0;
  padding-bottom: 10px;
}

footer .footer-links ul {
  padding: 0px;
  margin: 0;
  margin: 0 10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* flex-wrap: wrap;
	justify-content: center; */
}

footer .footer-links ul li {
  margin: 0px 10px;
}

.copy-right {
  text-align: end;
  padding-top: 70px;
  position: relative;
  right: 70px;
}

/* .terms-about-policy {
	text-align: center;
} */

.terms-about-policy a {
  font-size: 12px;
  color: #ffffff;
  text-decoration: unset;
  font-family: "poppins-regular";
  padding: 0px 10px;
}

.brder-left-right {
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
}

.copy-right p span {
  color: var(--first-color);
}

.footer-links a:hover {
  color: #f1480c;
}

/* ============================== */

/* Slider Css  */
.all-borders img {
  width: 97%;
  height: 380px;
  border: 1px solid #dcdcdc;
  margin-bottom: 20px;
  padding: 50px;
  background: #f9f9f9;
  object-fit: contain;
}

.on-small-border {
  border: 1px solid #dcdcdc;
  width: 87%;
  /* margin: 0px auto; */
  height: 130px;
  padding: 10px;
  background-color: #f9f9f9;
}

/* ================================================= */

.home-about-banner-btn a,
.home-about-banner-btn button {
  font-size: 13px;
  width: 100%;
  border: 0;
  box-shadow: none;
  outline: none;
}

h3.not-found-txt {
  font-size: 35px;
  font-family: hasnon-bold;
  color: var(--second-color);
  line-height: 46px;
  text-transform: uppercase;
  padding: 10px 0px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

@media (max-width: 1920px) {
  .background-video video {
    bottom: 0;
    height: 100% !important;
    left: 197px;
    position: absolute;
    right: 0;
    top: -68px;
    width: 90%;
    z-index: -1;
    object-fit: cover;
  }

  section.banner-sec {
    padding-bottom: 140px;
  }

  .banner-text p {
    width: 77%;
    text-align: justify;
  }

  .about-icon-img {
    top: 69px;
    right: 143px;
  }

  .login-memeber-ul li::before {
    width: 272px;
  }
}

@media (max-width: 1600px) {
  .background-video video {
    left: 140px;
    top: -96px;
  }

  .login-form-all {
    width: 32%;
  }

  .register-head p {
    width: 100%;
  }

  .forget-password-head h2 {
    font-size: 24px !important;
  }

  .login-head p {
    font-size: 13px;
  }

  .nav-home ul li a {
    font-size: 11px;
  }

  /* .about-banner-text h5 {

    letter-spacing: 0px !important;
} */

  .para-check p {
    font-size: 12px !important;
  }

  .content-wrapper h5 {
    letter-spacing: 3px !important;
  }
}

@media (max-width: 1536px) {
  /* .background-video video {
    left: 127px !important;
  } */

  .on-small-border {
    height: 140px !important;
  }

  .login-memeber-ul li::before {
    width: 216px !important;
  }
}

@media (max-width: 1440px) {
  .background-video video {
    bottom: 0;
    height: 100% !important;
    left: 37px;
    position: absolute;
    right: 0;
    top: -67px;
    width: 100%;
    z-index: -1;
    object-fit: contain;
  }

  .first-dv h2 {
    font-size: 40px;
    color: #ffffff;
    font-family: "poppins-bold";
    line-height: inherit;
  }

  .video-on-home {
    bottom: 150px;
  }

  /* .three-links {
    top: 360px;
    left: -90px;
  } */

  /* .three-links {
    top: 300px;
  } */
}

@media (max-width: 1366px) {
  .background-video video {
    bottom: 0;
    height: 100% !important;
    left: 127px;
    position: absolute;
    right: 0;
    top: -105px;
    width: 91%;
    z-index: -1;
    object-fit: contain;
  }

  .video-on-home {
    bottom: 120px;
  }

  .banner-text p {
    width: 84%;
  }

  .banner-text h4 {
    font-size: 12px;
  }

  .login-memeber-ul li::before {
    width: 172px !important;
  }

  .home-about-banner-btn a,
  .home-about-banner-btn button {
    font-size: 13px;
  }

  .spin-div h2 {
    font-size: 32px;
    color: #ffffff;
    width: 47%;
    line-height: 33px;
    padding-top: 6px;
  }

  .para-check p {
    font-size: 10px !important;
  }

  .on-small-border {
    height: 108px !important;
    object-fit: contain;
    width: 130px;
  }
  .all-become-member-div .become-member-ul {
    padding: 10px 10px 0;
  }

  /* .content-wrapper p {
		width: 48%;
	} */

  .content-wrapper h5 {
    letter-spacing: 0px;
  }
  .shop-category-ul li {
    padding: 16px 8px;
  }
}

@media (max-width: 1280px) {
  /* Navbar  */
  .search input {
    max-width: 160px;
  }
  .search img {
    right: 0;
  }
  .search i {
    left: 6px;
  }
  /* Navbar  */
  .shop-category-ul li {
    padding: 14px;
  }
  .background-video video {
    width: 89% !important;
  }

  .grass-img img {
    width: 57%;
  }

  .about-banner-text h2 {
    font-size: 30px !important;
  }

  /* .three-links {
    left: -64px;
  } */

  .content-wrapper h5 {
    letter-spacing: 1px !important;
  }

  .on-small-border {
    height: 108px !important;
    object-fit: cover;
  }

  .content-wrapper h5 {
    letter-spacing: 0px;
  }
}

@media (max-width: 1199px) {
  .shop-category-ul li a {
    display: flex;
    gap: 3px;
  }
  .shop-category-ul li a img {
    min-width: 16px;
    width: 100%;
    max-width: 16px;
  }
  .shop-category-ul li {
    padding: 10px;
  }
}
@media (max-width: 1150px) {
  /* ~New  */
  /* Navbar  */
  .search input {
    max-width: 130px;
  }
  /* Navbar  */
  .rating-start {
    right: 60px;
  }

  .for-slightly-moving {
    right: 90px;
  }
  .background-video video {
    top: -64px;
    left: -40px;
    width: 100% !important;
  }
  /* ~New  */

  .content-wrapper {
    padding-bottom: 20px;
  }

  .banner-text h4 {
    font-size: 11px;
  }

  .forum-img img {
    margin-bottom: 11px;
    width: 60%;
  }

  .video-on-home {
    bottom: 50px;
  }

  .about-banner-text p {
    font-size: 14px !important;
    line-height: 25px;
  }

  .shop-inner-page-sec {
    padding-bottom: 19px !important;
  }

  .about-img img {
    width: 85%;
    position: relative;
  }

  .about-icon-img {
    top: 39px;
    right: 83px;
  }

  .img-2 h5 {
    font-size: 11px;
  }

  .img-1 h5 {
    font-size: 10px;
  }

  .img-1 {
    width: 72%;
  }

  .img-1 img {
    width: 40% !important;
  }

  .img-2 img {
    width: 40% !important;
  }

  .grass-img img {
    width: 65%;
  }

  .shop-banner-text {
    padding-bottom: 10px;
  }

  .subscribe-btn {
    right: 0px;
  }

  section.become-member-sec {
    padding: 40px;
  }

  .search input {
    padding: 10px 15px;
  }

  .about-banner-text h2,
  .order-zaza-head h2,
  .content-wrapper h2,
  .thank-you-head h3,
  .not-found-txt {
    font-size: 28px !important;
  }

  .home-about-banner-btn {
    width: 46%;
  }

  .spin-btn {
    width: 27%;
  }



  section.spin-sec .row {
    padding: 10px 15px 10px;
    height: auto;
  }
}

@media (max-width: 1024px) {
  /* ~New   */
  /* mobile nav  */
  .profile-on-header {
    /* flex-direction: column; */
    padding: 0 10px;
  }
  .logoutButton {
    background-color: var(--first-color);
    color: #ffffff !important;
    border-radius: 6px;
    display: block;
    font-family: "poppins-regular";
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    border: 0;
    outline: none;
    width: 90%;
  }
  .mobile_header .search {
    width: 100%;
    margin: 20px 0;
  }
  .mobile_header .search input {
    max-width: 75%;
  }
  .search input {
    padding: 10px 15px 10px 32px;
  }
  /* Header */
  .mobile_header .cancel {
    top: 0;
  }
  .mobile_header ul.mobile_menus {
    margin-top: 4rem !important;
  }
  .shopping-bag-icon p {
    top: 0px;
    right: 34px;
  }
  /* Header  */
  /* mobile nav  */
  section.banner-sec {
    padding-top: 0;
  }
  .for-slightly-moving {
    right: 0;
  }
  .background-video video {
    top: -100px;
    left: -60px;
  }

  /* membership */
  .become-member-head {
    padding-bottom: 0px;
  }
  /* membership */

  /* ~New   */
  .side-move-pic {
    display: none;
  }

  .canvas_btn {
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 1;
  }

  .canvas_btn {
    padding: 10px;
  }

  .canvas_btn i {
    color: #d55302;
  }

  /* section.header-sec {
		display: none;
	} */

  section.header-sec .nav-home {
    display: none;
  }

  section.header-sec .all-for-flex {
    display: none;
  }

  .member-ul {
    padding-left: 10px;
  }

  .member-btn {
    width: 90%;
    margin: 0 auto;
  }

  .first-dv h2 {
    font-size: 36px;
  }

  .grass-img {
    position: absolute;
    left: -28px;
    top: 0;
  }
}

@media (max-width: 991px) {
  .member-ul li {
    font-size: 13px;
  }

  .member-btn a {
    font-size: 14px;
  }

  section.become-member-sec {
    padding: 30px 5px;
  }
  .all-become-member-div .become-member-ul .paragraf_member p {
    font-size: 12px;
  }

  .Link-all img {
    padding-left: 0px;
    width: 28px;
  }

  /* .banner-text h2 {
    font-size: 18px !important;
    line-height: 21px;
    text-align: center !important;
  } */

  .banner-text h4 {
    font-size: 8px;
  }

  .banner-button {
    margin-right: auto !important;
    text-align: center;
    width: 50%;
  }

  .background-video video {
    top: -97px;
    left: 40px;
  }
  .banner-text p {
    font-size: 12px;
  }

  .banner-button a {
    font-size: 12px;
  }

  .forum-img {
    position: relative;
    left: 70px;
  }

  .forum-text h3 {
    font-size: 12px;
  }

  .forum-text h4 {
    font-size: 12px;
    line-height: inherit;
  }

  .about-banner-text h2 {
    font-size: 19px;
  }

  .about-img img {
    width: 100%;
    position: relative;
  }

  .about-icon-img {
    top: 39px;
    right: -17px;
  }

  .about-banner-text h5 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  section.smoke-shop-section {
    padding: 0px;
  }

  .shop-img img {
    height: 100px;
  }

  .member-btn {
    width: 100%;
  }

  .spin-btn {
    width: 40%;
  }

  .subscribe-btn {
    width: 30%;
  }

  .newsletter-input input {
    width: 90%;
  }

  .subscribe-btn {
    right: 34px;
  }

  .footer-address p {
    font-size: 8px;
    text-align: start !important;
  }

  /* .banner-about-sec .about-banner-text .description {
    display: -webkit-box;
    -webkit-line-clamp: 8 !important;
    -webkit-box-orient: vertical !important;
    overflow: visible !important;
    text-overflow: ellipsis;
  } */

  /* membership  */
  .first-dv h5 {
    font-size: 12px !important;
    line-height: 16px;
  }
  .first-dv h2 {
    font-size: 26px;
  }
  .dollar img {
    width: 18px;
  }
  .set_membership_details .all-become-member-div {
    /* height: auto; */
    /* margin-top: 10px; */
    /* overflow: scroll; */
    margin: 20px 0;
  }
  /* membership  */

  /* newsletter  */
  .subscribe {
    max-width: 90%;
  }
  /* newsletter  */

  /* Footer  */
  .footer-web-logo {
    top: 0;
  }
  .footer-anchor {
    text-align: center;
  }
  .footer-anchor a {
    font-size: 16px;
    text-align: center !important;
    margin: 10px 0;
    display: inline-block;
  }

  .footer-address p {
    text-align: center !important;
    font-size: 16px;
  }
  footer .footer-links ul {
    justify-content: center;
    flex-wrap: wrap;
  }
  .copy-right {
    right: 0;
  }
  .copy-right p {
    font-size: 16px;
    padding-bottom: 5px !important;
    text-align: center !important;
  }
  .terms-about-policy {
    text-align: center;
  }
  .terms-about-policy a {
    font-size: 14px !important;
  }
  footer .col-lg-2 {
    margin: 10px 0;
  }
  footer {
    padding: 30px 0;
  }
  .copy__wrapp .row {
    flex-direction: column-reverse;
  }

  .copy__wrapp .row .copy-right {
    padding: 16px;
  }
  footer .footer-links ul {
    flex-direction: row;
  }
  /* Footer  */
  .all-become-member-div .become-member-ul {
    padding: 10px 10px 0;
  }
}

@media (max-width: 820px) {
  .forum-img img {
    margin-bottom: 11px;
    width: 40%;
  }

  .forum-img {
    position: relative;
    left: 60px;
  }

  .Link-all img {
    padding-left: 0px;
    width: 18px;
  }

  .grass-img {
    left: -38px;
  }

  .terms-about-policy a {
    padding: 0px 4px;
  }

  .background-video video {
    top: -90px;
  }

  .gallery-sec .gallery_box .gallery-all:nth-child(1) {
    flex: 0 0 100% !important;
  }

  .gallery-sec .gallery_box .gallery-all {
    flex: 0 0 100% !important;
  }
}

@media (min-width: 820px) and (max-width: 1280px) {
  .gallery-sec .gallery_box .gallery-all {
    flex: 0 0 47.6%;
  }
}

@media (max-width: 768px) {
  /* ~New  */
  .banner-text .banner-text h2 {
    text-align: left;
  }
  .banner-text .banner-button {
    margin: 10px 0 30px !important;
  }
  .banner-button a {
    margin: 0;
  }

  .newsletter-sec .subscribe-btn {
    margin: 0 !important;
    height: 100%;
  }
  .newsletter-sec .all-button {
    margin: 0 !important;
  }
  .home-about-banner-responsive .home-about-banner-btn {
    margin: 1.5rem auto !important;
  }
  .about-banner-text.banner-text.home-about-banner-responsive .banner-button {
    margin: 1rem auto !important;
  }
  .banner-button.spin-btn {
    margin: auto !important;
  }
  /* ~New  */
  .content-wrapper p {
    width: 95%;
  }

  .forum-img {
    position: relative;
    left: 30px;
  }

  .gallery-sec .gallery_box .gallery-all:nth-child(1) {
    flex: 0 0 100% !important;
  }

  .gallery-sec .gallery_box .gallery-all {
    flex: 0 0 100% !important;
  }

  .order-zaza-head h2 {
    font-size: 18px !important;
    line-height: 26px;
  }

  .order-zaza-sec {
    text-align: center;
    padding-top: 2rem;
  }

  .footer-web-logo img {
    width: 80px !important;
  }

  .subscribe {
    position: relative;
    max-width: 100% !important;
    margin: 10px auto;
  }

  .canvas_btn {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 20px;
    z-index: 1;
  }

  .web-logo {
    text-align: start !important;
  }

  .center_thistext {
    font-size: 18px;
  }

  .galler-head {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  /* ~New  */
  .mobile_menus .menu-items {
    padding-right: 0;
  }
  .all-forum-img {
    max-width: 360px;
    margin-left: auto;
  }
  .rating-start {
    right: 160px;
  }
  .forum-img {
    left: 120px;
    word-break: break-word;
  }
  .background-video video {
    top: -30px;
    left: -100px;
  }
  section.banner-sec {
    padding-bottom: 180px;
  }
  .background-video video {
    top: -60px;
    left: -100px;
  }

  .three-links {
    opacity: 0;
  }

  /* Shop Sec  */
  .about-banner-text.shop-banner-text {
    text-align: center;
    margin: 1rem 0;
  }

  .about-banner-text.shop-banner-text p {
    max-width: 100%;
    width: 100%;
  }
  .shop-img img {
    height: 300px;
    object-fit: cover;
  }
  /* Shop Sec  */

  .spin-div h2 {
    width: 100%;
  }

  .spin-div p {
    margin-bottom: 10px;
  }

  .all-shop-product-div.shop-inner-dv {
    text-align: center;
  }
  /* ~New  */
}

@media (max-width: 650px) {
  /* ~New */
  .background-video video {
    top: -40px;
    left: -197px;
    width: 900px !important;
  }
  /* ~New */
}

@media (max-width: 575px) {
  /* .footer-anchor {
    text-align: start !important;
  } */
  /* .footer-web-logo {
    position: relative;
    top: 22px;
    text-align: start !important;
  } */

  .web-logo img {
    width: 96px;
    object-fit: contain;
  }
  .first-dv h5 {
    font-size: 10px !important;
    font-family: stretch-pro;
    padding-right: 5px;
    text-transform: uppercase;
    margin: 10px 0;
  }

  .dollar img {
    width: 7px !important;
    position: relative;
    bottom: 6px;
    right: 3px;
  }

  .first-dv h2 {
    font-size: 30px;
  }

  .first-dv h2 span {
    font-size: 16px !important;
  }

  .member-ul li {
    font-size: 16px;
  }

  .banner-text p {
    width: 50%;
  }

  section.banner-sec {
    padding-top: 30px;
    padding-bottom: 330px;
  }

  .background-video video {
    width: 100%;
    /* right: -18px; */
  }

  .forum-img {
    position: relative;
    left: 0;
    margin: 20px;
    max-width: 80%;
  }

  /* .video-img img {
    width: 97%;
  } */

  .video-on-home {
    display: block;
  }

  .video-text p {
    font-size: 14px;
    width: 100%;
  }

  .video-text {
    text-align: center;
    padding-left: 0px;
  }

  .video-img i {
    right: 4px;
    bottom: 67px;
  }

  .all-forum-img {
    display: block;
    /* justify-content: end; */
    /* display: none; */
  }

  .forum-img img {
    margin-bottom: 11px;
    width: 25%;
  }

  .shop-banner-text p {
    padding: 5px !important;
    width: 100%;
  }

  .grass-img img {
    display: none;
  }

  .about-banner-text {
    margin-top: 30px;
  }

  .banner-text h5 {
    font-size: 9px;
    text-align: center !important;
  }

  .spin-btn {
    width: 85%;
  }

  section.spin-sec .row {
    padding: 0px;
  }

  .spin-div h2 {
    width: 100%;
  }

  .gallery-img {
    margin: 10px 0px;
  }

  .gallery-img img {
    width: 100%;
    height: 250px;
  }

  .galler-head {
    padding-top: 10px;
  }

  /* .footer-web-logo {
    position: relative;
    top: 0px;
  } */

  .copy-right {
    text-align: initial;
    right: 0px;
    padding: 0px;
  }

  /* .terms-about-policy {
    text-align: initial;
    padding-bottom: 10px !important;
    text-align: start !important;
  } */

  /* .about-banner-text h2 {
    font-size: 18px;
    line-height: 15px !important;
  } */

  .subscribe-btn {
    width: 43%;
  }

  .banner-text {
    margin-bottom: 20px;
  }

  section.gallery-sec {
    padding-bottom: 0px;
  }

  .play-border {
    top: 60px;
    right: 0px;
  }

  .home-about-banner-responsive p {
    width: 100%;
  }

  .become-member-head {
    padding-bottom: 20px;
  }

  section.newsletter-sec {
    padding-bottom: 110px;
  }

  .video-img img {
    width: 65%;
    margin: auto;
    display: block;
  }
  .video-on-home {
    bottom: 20px;
  }
  /* ~New */
  /* Banner  */
  .background-video video {
    /* top: -155px; */
    /* width: 240px !important; */
    position: relative;
    object-fit: cover;
    left: 50%;
    transform: translateX(-50%);
    top: 0 !important;
    height: 400px !important;
  }

  .all-forum-img {
    max-width: 70%;
    margin: auto !important;
  }

  .rating-start {
    right: 0;
  }

  .play-border {
    top: 50%;
    right: unset;
    left: 50%;
    transform: translate(-50%, -70%);
  }
  /* Banner  */
  .first-dv {
    height: 160px;
  }

  .newsletter-sec .subscribe-btn {
    margin: 1rem auto !important;
  }
  /* ~New */

  .shop-banner-text {
    text-align: center;
  }

  .subscribe-btn {
    position: relative;
    top: 16px;
    bottom: 0;
    width: 90%;
    left: 0;
  }

  .for-responsive-member {
    border: none !important;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
  }

  .faq-all .accord-para p {
    font-size: 14px;
  }

  .all-become-member-div .become-member-ul .member_btns {
    top: 206px;
  }
  .set_membership_details .all-become-member-div {
    height: 440px;
  }
}

@media (max-width: 540px) {
  /* ~New  */
  .all-forum-img {
    max-width: 100%;
  }
  .forum-img img {
    margin-bottom: 12px;
    width: 60%;
  }
  .rating-start {
    right: 0;
  }
  .banner-sec {
    padding-bottom: 348px !important;
  }
  .about-banner-text h2,
  .order-zaza-head h2,
  .content-wrapper h2,
  .thank-you-head h3,
  .not-found-txt {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .gallery-sec {
    padding-bottom: 30px !important;
  }
  /* shop sec  */
  section.smoke-shop-section {
    padding: 20px !important;
  }
  /* shop sec  */
  /* ~New  */
  .subscribe-btn {
    width: 100%;
  }

  /* .newsletter-input input {
    padding: 20px 3px;
  } */

  .about-banner-text p span {
    font-size: 14px;
    line-height: 15px !important;
  }

  .banner-text p {
    width: 100% !important;
  }

  .shop-category-ul li a {
    font-size: 12px;
  }

  .for-slightly-moving {
    position: relative;
    right: 0px;
    margin-bottom: 10px;
    text-align: justify;
  }

  /* .background-video video {
    top: -473px !important;
    width: 95% !important;
    text-align: center !important;
    position: absolute;
    left: 15px !important;
  } */

  /* .only-for-banner-btn a {
    letter-spacing: 4px;
    position: relative;
    top: 620px;
    margin: 0px;
  } */

  section.banner-sec {
    padding-top: 30px;
    padding-bottom: 600px;
  }

  .banner-button {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  .banner-about-sec .about-banner-text .description {
    margin-bottom: 60px !important;
  }

  .subscribe-btn {
    left: 50%;
    transform: translateX(-50%);
    top: 12px;
  }
  .subscribe-btn button.all-button {
    width: 90%;
    margin: 0.5rem auto 0 !important;
  }
  .shop-inner-page-sec {
    padding-bottom: 10px !important;
  }

  section.banner-about-sec {
    padding-bottom: 0px !important;
  }

  .shop-inner-page-sec {
    padding-bottom: 0px !important;
  }

  /* Starr */
  /* section.banner-sec {
    padding-top: 25px;
    padding-bottom: 600px !important;
  } */

  /* .forum-img {
    position: relative;
    left: 0;
    top: 0px;
    margin: 20px;
  } */

  /* .background-video video {
    top: -556px !important;
    width: 90% !important;
    text-align: center !important;
    position: absolute;
    left: 25px !important;
  } */

  /* .for-slightly-moving {
    position: relative;
    right: 1px;
    top: 30px;
    margin-bottom: 71px;
    text-align: center;
  } */

  .all-forum-img {
    display: block;
    text-align: center;
  }

  .banner-about-sec .about-banner-text .description {
    margin-bottom: 0px !important;
  }

  /* .only-for-banner-btn a {
    letter-spacing: 4px;
    position: relative;
    top: 850px;
    margin: 0px;
  } */

  /* .rating-start {
    position: absolute;
    top: 160px;
    right: 205px;
  } */

  /* .forum-img img {
    margin-bottom: 11px;
    width: 30% !important;
  } */

  .forum-text h3 {
    max-width: 100% !important;
    text-align: center !important;
  }

  /*  */
  /* footer .footer-links ul {
    padding: 0px;
    margin: 0;
    margin: 0 -10px;
    list-style: none;
    display: flex;
    column-count: 2;
    flex-wrap: wrap;
    justify-content: flex-start;
  } */
}

@media (max-width: 480px) {
  .mobile_header ul.mobile_menus {
    /* margin-top: 3rem !important; */
    width: 96%;
  }
  .mobile_header .search input {
    max-width: 74%;
    width: 100%;
  }
  /* .mobile_header .search input {
    max-width: 91%;
    width: 100%;
  } */
  .mobile_header {
    width: 100%;
  }

  .search input {
    padding: 10px 38px !important;
  }
}

@media (max-width: 414px) {
  /* ~New  */
  .video-img img {
    width: 75%;
  }
  /* ~New  */
  .subscribe-btn {
    width: 100%;
  }

  .newsletter-input input {
    padding: 20px 25px;
  }

  .about-banner-text p span {
    font-size: 14px;
    line-height: 15px !important;
  }

  .banner-text p {
    width: 100% !important;
  }

  .shop-category-ul li a {
    font-size: 12px;
  }

  /* section.banner-sec {
    padding-top: 30px;
    padding-bottom: 500px;
  } */

  .banner-button {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  /* .subscribe-btn {
    right: 30px;
    top: 72px;
  } */

  .shop-inner-page-sec {
    padding-bottom: 10px !important;
  }

  section.banner-about-sec {
    padding-bottom: 0px !important;
  }

  .shop-inner-page-sec {
    padding-bottom: 0px !important;
  }

  /* Starr */
  section.banner-sec {
    padding-bottom: 269px !important;
  }

  /* .forum-img {
    position: relative;
    left: 0;
    top: 30px;
    margin: 20px;
  } */

  /* .background-video video {
    top: -500px !important;
    width: 90% !important;
    text-align: center !important;
    position: absolute;
    left: 25px !important;
  } */

  /* .for-slightly-moving {
    position: relative;
    right: 1px;
    top: 30px;
    margin-bottom: 71px;
    text-align: center;
  } */

  .all-forum-img {
    display: block;
    text-align: center;
  }

  .banner-about-sec .about-banner-text .description {
    margin-bottom: 0px !important;
  }

  /* .only-for-banner-btn a {
    letter-spacing: 4px;
    position: relative;
    top: 866px;
    margin: 0px;
  } */

  /* .rating-start {
    position: absolute;
    right: 140px;
    top: 180px;
  } */

  /* .forum-img img {
    margin-bottom: 11px;
    width: 50% !important;
  } */

  .forum-text h3 {
    max-width: 100% !important;
    text-align: center !important;
  }

  /*  */
}

@media (max-width: 393px) {
  /* ~New  */
  section.banner-sec {
    padding-bottom: 360px !important;
  }
  .video-img img {
    width: 95%;
  }
  /* ~New  */
  .subscribe-btn {
    width: 100%;
  }

  .newsletter-input input {
    padding: 20px 25px;
  }

  .about-banner-text p span {
    font-size: 14px;
    line-height: 15px !important;
  }

  .banner-text p {
    width: 100% !important;
  }

  .shop-category-ul li a {
    font-size: 12px;
  }

  .banner-button {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  /* .subscribe-btn {
    right: 0px;
    top: 72px;
  } */

  .shop-inner-page-sec {
    padding-bottom: 10px !important;
  }

  section.banner-about-sec {
    padding-bottom: 0px !important;
  }

  .shop-inner-page-sec {
    padding-bottom: 0px !important;
  }

  /* Starr */

  /* .forum-img {
	  position: relative;
	  left: 0;
	  top: 60px;
	  margin: 20px;
	} */

  /* .background-video video {
	  top: -530px !important;
	  width: 90% !important;
	  text-align: center !important;
	  position: absolute;
	  left: 25px !important;
	} */

  /* .for-slightly-moving {
	  position: relative;
	  right: 1px;
	  top: 30px;
	  margin-bottom: 71px;
	  text-align: center;
	} */

  /* .all-forum-img {
	  display: block;
	  text-align: center;
	} */

  .banner-about-sec .about-banner-text .description {
    margin-bottom: 0px !important;
  }

  /* .only-for-banner-btn a {
	  letter-spacing: 4px;
	  position: relative;
	  top: 900px;
	  margin: 0px;
	} */

  /* .rating-start {
	  position: absolute;
	  top: 170px;
	  right: 132px;
	} */

  /* .forum-img img {
	  margin-bottom: 11px;
	  width: 50% !important;
	} */

  /* .forum-text h3 {
	  max-width: 100% !important;
	  text-align: center !important;
	} */

  /*  */
}

@media (max-width: 375px) {
  /* ~New  */
  .background-video video {
    transform: translateX(-48%);
  }
  .video-on-home {
    bottom: 0;
    left: 0;
  }
  section.banner-sec {
    padding-bottom: 300px !important;
  }
  .about-banner-text h2,
  .order-zaza-head h2,
  .content-wrapper h2,
  .thank-you-head h3 {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  /* shop sec  */
  section.smoke-shop-section {
    padding: 20px 0 !important;
  }
  /* shop sec  */
  .mobile_header ul.mobile_menus {
    width: 96%;
  }

  /* ~New  */
  .newsletter-input input {
    padding: 10px 30px !important;
  }

  .subscribe-btn {
    width: 44%;
  }

  .subscribe-btn {
    right: 18px;
  }

  .newsletter-input input {
    width: 100%;
  }

  section.newsletter-sec {
    padding: 20px 0px;
    padding-bottom: 70px;
  }

  /* .subscribe-btn {
    right: 0px;
    top: 60px;
  } */

  .newsletter-input input {
    padding: 20px 3px;
  }

  /* .background-video video {
    top: -336px !important;
    width: 52% !important;
    text-align: center !important;
  } */

  .banner-text p {
    width: 100% !important;
  }

  /* .forum-img img {
    margin-bottom: 11px;
    width: 25%;
  } */

  /* .for-slightly-moving {
    position: relative;
    right: 0px;
    margin-bottom: 10px;
    text-align: justify;
  } */

  /* .only-for-banner-btn a {
    letter-spacing: 4px;
    position: relative;
    top: 510px;
    margin: 0px;
  } */

  .banner-button {
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

@media (max-width: 325px) {
  /* ~New  */
  .web-logo img {
    width: 90px;
  }
  .background-video video {
    transform: translateX(-48%);
    height: 300px !important;
    object-fit: cover;
    width: 100% !important;
  }

  .forum-img {
    text-align: left !important;
  }
  .forum-text h3 {
    text-align: center !important;
  }
  .forum-text h4 {
    text-align: center;
  }
  .forum-img {
    max-width: 90%;
  }
  .forum-img img {
    width: 75%;
  }

  /* shop sec  */
  .shop-category-ul li {
    padding: 10px 10px 10px 0;
  }
  /* shop sec  */

  /* footer  */
  footer {
    padding: 30px 10px;
  }
  /* footer  */
  /* ~New  */
}

@media (max-width: 290px) {
  .subscribe-btn {
    width: 100%;
  }

  .newsletter-input input {
    padding: 20px 3px;
  }

  .about-banner-text p span {
    font-size: 14px;
    line-height: 15px !important;
  }

  section.banner-sec {
    padding-bottom: 260px !important;
  }

  .banner-text p {
    width: 100% !important;
  }

  .shop-category-ul li a {
    font-size: 12px;
  }

  /*Start  */

  /* .forum-img {
    position: relative;
    left: 0;
    top: 15px;
    margin: 20px;
  } */

  /* .only-for-banner-btn a {
    letter-spacing: 4px;
    position: relative;
    top: 725px;
    margin: 0px;
  } */

  /* .background-video video {
    top: -405px !important;
    width: 90% !important;
    text-align: center !important;
    position: absolute;
    left: 25px !important;
  } */

  /* .for-slightly-moving {
    position: relative;
    right: 1px;
    top: 30px;
    margin-bottom: 71px;
    text-align: center;
  } */

  /* .forum-img img {
    margin-bottom: 11px;
    width: 50% !important;
  } */

  /* .all-forum-img {
    display: block;
    text-align: center;
  } */

  /* .banner-about-sec .about-banner-text .description {
    margin-bottom: 0px !important;
  } */

  .rating-start {
    right: -10px;
  }

  /* End */

  .banner-button {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  /* .subscribe-btn {
    right: 0px;
    top: 72px;
  } */

  .shop-inner-page-sec {
    padding-bottom: 10px !important;
  }

  section.banner-about-sec {
    padding-bottom: 0px !important;
  }

  .shop-inner-page-sec {
    padding-bottom: 0px !important;
  }

  h2 {
    font-size: 28px !important;
    font-weight: 600;
    line-height: 30px !important;
  }

  .all-become-member-div .become-member-ul {
    border: none !important;
  }
}

.subscribe-btn a,
.subscribe-btn button {
  letter-spacing: 2px;
}
